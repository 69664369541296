@media only screen and (min-width: 320px) and (max-width: 420px) {
    .dashboard-content .dashboard-box .panel .panel-heading .home-dash-heading span:first-child {
        width: 20px;
    }

    .dashboard-content .dashboard-box .panel .panel-heading .home-dash-heading .home-dash-text {
        font-size: 12px;
        width: calc(100% - 40px);
    }

    .dashboard-content .dashboard-box .panel .panel-heading .home-dash-heading a .dots-align {
        position: absolute !important;
        top: 0px !important;
        right: -3px;
    }

    .dashboard-content .dashboard-global-wrap .dashboard-box .panel .panel-heading .home-dash-heading a .dots-align {
        position: absolute !important;
        top: -5px !important;
        right: 0;
    }

    #trip-view-content {
        padding: 0px !important;
    }

    .trip-view-content-top-box {
        padding: 5px !important;
        margin: 2px !important;
    }

    .trip-view-content-top-box .trip-box-top h4 {
        font-size: 11px !important;
    }

    .trip-view-content-top-box .trip-box-bottom {
        font-size: 20px !important;
    }

    #trip-view-detail-content .trip-view-detail-content-bottom {
        padding: 10px !important;
    }

    #trip-view-detail .trip-view-bottom .tri#trip-view-detail-content .trip-view-detail-content-bottom .detail-content-bottom-inner-leftp-detail-time {
        font-size: 16px !important;
        width: 85% !important;
    }

    #trip-view-detail-content ul li {
        margin-right: 0 !important;
    }

    #trip-view-detail-content ul li span:last-child {
        padding-left: 2px !important;
    }

    #trip-view-detail-content .trip-view-detail-content-bottom .detail-content-bottom-inner-right {
        margin-left: 10px !important;
    }

    #trip-view-detail-content .trip-view-detail-content-bottom .detail-content-bottom-inner-right {
        width: calc(100% - 113px) !important;
    }

    .layer-details-heading-left>span {
        margin-top: 3px;
        float: left;
    }

    .layer-details-heading-left a span:nth-child(1) {
        position: relative;
        top: 3px;
    }

    .layer-details-heading-left a span:nth-child(2) {
        font-size: 14px !important;
    }

    .layer-details-heading-right {
        width: 40%;
        margin-top: -2px;
        padding-left: 14px;
    }

}


/* .navbar .container-fluid{display:none; } */
.navbar {
    padding: 0;
    height: 0;
    min-height: 0;
}

.main-header .logo {
    display: none;
}

#sidebar.main-sidebar.active {
    text-align: center;
    display: block;
    width: 100%;
    position: absolute;
    z-index: 999;
    background: #ffffff;
    top: 93px;
    bottom: 55px;
}

#sidebar ul li a {
    color: #231f20;
    font-size: 16px !important;
    font-weight: 400;
}

#sidebar ul li a span:nth-child(3) {
    font-size: 16px;
    float: right;
    transform: rotate(270deg);
    margin-right: 10px;
}

#sidebar ul li a span:nth-child(3) {
    transform: rotate(0deg);
}

#sidebar ul li a.collapsed span:nth-child(3) {
    transform: rotate(270deg);
}

#sidebar ul li ul li a {
    border-bottom: 1px solid #23292D !important;
    padding-left: 50px !important;
}

a[aria-expanded="false"]::before,
a[aria-expanded="true"]::before {
    top: auto;
    bottom: 5px;
    right: 50%;
    -webkit-transform: translateX(50%);
    -ms-transform: translateX(50%);
    transform: translateX(50%);
}

.icon-menu_mobile_white:hover,
.icon-menu_mobile_white:active {
    color: #cccccc;
}

.profile-drop-down {
    padding-left: 19px;
}

.home-dash-body {
    padding: 24px 0;
}

.nav>li {
    margin-bottom: 5px;
}

.progress {
    margin: 0px;
}

.notification-body p {
    padding: 23px 0px 0;
}

.navbar-btn {
    padding: 0px;
}

.list-unstyled li span {
    margin: 0 auto;
    text-align: center;
}

.list-unstyled li span {
    padding-left: 8px;
}

a[aria-expanded="false"]::after {
    position: absolute;
    right: 36%;
    text-align: center;
    font-size: 16px;
}

#sidebar.main-sidebar.active {
    left: 0;
}

#sidebar.active .sidebar-header img.mob-logo {
    display: none;
}

#sidebar.active .sidebar-header img.desk-logo {
    display: block !important;
}

#sidebar .sidebar-header {
    display: none;
}

.sidebar-header.mobile-view {
    padding: 20px 15px 0px;
    background: #bf2349;
}

.main-sidebar-map .sidebar-header.mobile-view {
    padding: 20px 15px 20px;
    border-radius: 5px 5px 0 0;
}

.sidebar-header.mobile-view>img {
    margin: 5px auto 0;
    display: block;
    max-width: 150px;
}

#sidebar ul.components {
    padding: 0;
    margin-bottom: 10px;
    display: none;
}

#sidebar.active ul.components {
    display: block;
}

#sidebar.active ul li a {
    text-align: left;
}

#sidebar.active ul li a .icon-logout {
    color: #bf2349;
}

.menu-icon {
    display: none;
}

.mobile-footer {
    position: fixed;
    margin-top: 30px;
    bottom: 0;
    width: 100%;
    z-index: 9999;
    background: #23292D;
    padding: 10px 0;
}

.mobile-footer ul {
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: center;
}

.mobile-footer ul li {
    width: 25%;
    float: left;
}

.mobile-footer li img {
    width: 30px;
}

#home {
    margin-bottom: 30px;
}

#sidebarCollapse1 {
    display: block;
    position: absolute;
    top: 15px;
    right: auto;
    left: 0;
}

#sidebarCollapse1 span {
    color: #ffffff;
}

#sidebarCollapse1.active span:before {
    content: '\0042';
}

.content-wrapper {
    margin-bottom: 70px;
}

.dashboard-filters-left {
    margin-bottom: 30px;
}

.dashboard-filters,
.edit-dashboard-wrapper,
.dashboard-reports-wrap,
.map-left-menu,
.map-main-heading,
.main-sidebar-map #sidebarCollapse-map,
.map-filter-links {
    display: block;
}

.dashboard-content {
    margin: 15px 20px !important;
}

.dashboard-stops-wrap {
    clear: both;
}

.menu-dashboard-wrapper .menu-dashboard {
    margin-top: 10px;
}

.menu-dashboard .menu-dashboard-list {
    display: flex;
    margin: 20px 0;
    float: left;
}

.menu-dashboard ul li {
    margin: 0;
    padding: 0;
}

.menu-dashboard .menu-dashboard-list li:not(:last-child) {
    width: 49%;
    margin-right: 20px;
}

.menu-dashboard .menu-dashboard-list li::nth-child(2) {
    margin-right: 10px;
}

.menu-dashboard ul li:nth-child(2) {
    margin-right: 0 !important;
}

.menu-dashboard ul li:nth-child(3) {
    display: none;
}

.menu-dashboard .menu-dashboard-list li:last-child {
    float: left;
    padding-right: 0;
    width: 30px;
    margin-left: 5px;
}

.menu-dashboard .dashboard-custom-search-input {
    height: 40px;
    border-radius: 4px;
    padding: 0;
    background: #ffffff;
}

.menu-dashboard .dashboard-custom-search-input input {
    height: 40px;
    box-shadow: none;
    border: none;
}

.menu-dashboard .dashboard-custom-search-input input:hover,
.menu-dashboard .dashboard-custom-search-input input:focus,
.menu-dashboard .dashboard-custom-search-input input:active:focus {
    box-shadow: none;
    outline: none;
    border: none;
}

.menu-dashboard .menu-dashboard-list li select {
    height: 40px;
}

.menu-dashboard .dashboard-custom-search-input button {
    background: #fff;
    border: none;
    border-left: none;
    padding: 6px;
}

.menu-dashboard .dashboard-custom-search-input button:focus,
.menu-dashboard .dashboard-custom-search-input button:active:focus {
    box-shadow: none;
    outline: none;
}

.menu-dashboard .dashboard-custom-search-input button img {
    width: 25px;
}

.menu-dashboard .menu-dashboard-day {
    position: relative;
    width: 100%;
}

.menu-dashboard .menu-dashboard-day select {
    width: 100%;
    -moz-appearance: none;
    -webkit-appearance: none;
    font-size: 16px;
    padding: 0px 12px;
    line-height: 1.2;
}

.menu-dashboard .menu-dashboard-day:after {
    cursor: pointer;
    content: '';
    position: absolute;
    width: 40px;
    height: 18px;
    background: url(https://staticcontent.inelcan.com/img/colapse_down.svg);
    background-repeat: no-repeat;
    top: 10px;
    right: 0;
    pointer-events: none;
    background-size: 50%;
    background-repeat: no-repeat;
}

/*    .map-mobile-filter:after{cursor: pointer;content:"\f0d7"; position: absolute;top:0;font-family: 'FontAwesome';
    font-size: 27px;color:#75787b;background:none;right: 10px;pointer-events: none;}*/
.menu-dashboard .menu-dashboard-list li:last-child span {
    padding: 6px 0;
    font-size: 23px;
    color: #ffffff;
}

.menu-dashboard .menu-dashboard-list #dashboard-sub-submenu {
    position: absolute;
    z-index: 9999;
    left: 0px;
    right: 0;
    height: 220px !important;
    top: 78px;
    width: 100%;
    padding: 10px 0 0 0;
}

.menu-dashboard .menu-dashboard-list #dashboard-sub-submenu span.up-arrow {
    width: 20px;
    height: 20px;
    background: #bf2349;
    padding: 0;
    transform: rotate(45deg);
    margin: 0 21px -14px 0;
    float: right;
    display: block;
    -moz-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -o-transform: rotate(45deg);
}

.menu-dashboard .menu-dashboard-list #dashboard-sub-submenu ul {
    background: #bf2349;
    padding: 20px 30px;
    height: 200px;
    clear: both;
}

.menu-dashboard .menu-dashboard-list #dashboard-sub-submenu li {
    color: #ffffff;
    border-bottom: 1px solid #000000;
    width: 100%;
    padding: 5px;
    font-size: 15px;
    display: block !important;
}

.menu-dashboard .menu-dashboard-list #dashboard-sub-submenu li:first-child {
    font-weight: 700;
}

.menu-dashboard .menu-dashboard-list #dashboard-sub-submenu li:not(:first-child) {
    padding-left: 25px;
}

.dashboard-global-body {
    margin-top: 0;
}

.dashboard-content .calculation .letters,
.dashboard-content .global-fleet {
    font-size: 12px;
}

.dashboard-content .calculation .numbers {
    font-size: 14px;
}

.dashboard-content .calculation .pull-left,
.global-fleet .pull-left,
.dashboard-content .calculation .pull-right,
.global-fleet .pull-right {
    float: none !important;
    display: block;
    width: 100%;
}

.dashboard-content .calculation,
.global-fleet {
    padding: 4px;
    height: 50px;
    clear: both;
}

.dashboard-content .dashboard-box .Numbers {
    font-size: 18px;
}

.dashboard-content .dashboard-box .Letters {
    font-size: 15px;
}

.dashboard-content .dashboard-box .panel .panel-heading .home-dash-heading {
    padding: 5px;
}

.dashboard-box .dashboard-box-body {
    height: 30px;
}

.progress-bar-home-dash span,
.dashboard-global-wrap table td,
.dashboard-state-wrap table td {
    font-size: 15px;
}

.dashboard-state-wrap .panel-body table tr td table tr {
    border-bottom: 5px solid #ffffff;
    border: 1px solid #ffffff;
}

.dashboard-content .dashboard-box .panel .panel-heading .home-dash-heading .home-dash-text,
.dashboard-content .dashboard-box .panel .panel-heading .home-dash-heading span:first-child {
    font-size: 13px;
}

.sidebar-map-content-wrap {
    position: absolute;
    ;
    /* height: 500px;*/
}

/* .sidebar-map-content-wrap .sidebar-map-content {/*height:100vh;*/
#sidebar-map {
    width: 100%;
    height: 8%;
}

.map-search-wrap .map-search-left {
    width: 100%;
    background: #bf2349;
    padding: 3px;
    border-radius: 5px;
}

.main-sidebar-map {
    padding: 0;
    /*z-index:1 !important;*/
}

.sidebar-map-content-wrap {
    z-index: 0;
}

.main-sidebar-map button {
    padding: 0;
}

.map-search-wrap {
    margin-top: 0;
}

.map-search-wrap .map-search-left .input-group {
    width: calc(100%);
    margin: auto;
}

.map-search-wrap .map-search-left .input-group input {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}

.map-search-wrap .map-search-left .input-group span button {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}

.map-search-wrap .map-search-right {
    width: 100%;
    /*padding:10px;*/
    position: relative;
    /*background:#e9eae9;*/
}

.map-search-wrap .map-search-right #list-view-header-wrap,
.map-search-wrap .map-search-right #layer-view {
    padding: 10px;
    background: #e9eae9;
    overflow: hidden;
}

.map-search-wrap .map-search-right #trip-view .trip-view-top,
.map-search-wrap .map-search-right #trip-view-detail .trip-view-top {
    padding: 10px;
    background: #ffffff;
}

.map-search-wrap .map-search-right #trip-view .trip-view-bottom,
.map-search-wrap .map-search-right #trip-view-detail .trip-view-bottom {
    padding: 10px;
    background: #d0d3d3;
    overflow: hidden;
}

.map-search-wrap .map-search-right #trip-view-detail .trip-view-top .trip-heading a span {
    font-size: 19px;
    color: #75787b;
    padding: 0 10px;
}

.map-search-wrap .map-search-right #trip-view-detail .trip-view-top .trip-heading a:last-child span {
    transform: rotate(180deg);
    padding-top: 5px;
}

.map-mobile-filter {
    width: 80%;
    float: left;
    margin-top: 7px !important;
}

.map-mobile-filter .select {
    -moz-appearance: none;
    -webkit-appearance: none;
    position: static;
}

.map-mobile-filter .select select {
    width: 100%;
    -moz-appearance: none;
    -webkit-appearance: none;
    font-size: 16px;
    padding: 0px 12px;
    line-height: 1.2;
    height: 40px;
    opacity: 0;
}

.map-mobile-filter .styledSelect {
    background: transparent;
    font-size: 14px;
    color: #000000;
    width: 100px;
}

.map-mobile-filter .styledSelect:after {
    cursor: pointer;
    content: "\f0d7";
    position: absolute;
    top: 0;
    font-family: 'FontAwesome';
    font-size: 27px;
    color: #75787b;
    background: none;
    right: 10px;
    pointer-events: none;
}

.map-search-right .map-accordion-group-filter {
    float: right;
    margin-top: 7px;
    font-size: 28px;
    cursor: pointer;
    border: 1px solid hsl(0, 0%, 80%);
    ;
    border-radius: 4px;
    width: 17%;
    height: 38px;
    text-align: center;
    color: hsl(0, 0%, 65%);
}

.map-search-right .map-accordion-group-filter:hover {
    border-color: hsl(0, 0%, 70%);
    color: hsl(0, 0%, 50%);
}

.map-search-right .map-accordion-group-filter a span {
    font-size: 25px;
}

.map-mobile-filter .options {
    top: 50px !important;
    background: #bdbec1;
    padding: 20px;
    box-shadow: none;
}

.map-mobile-filter .options li {
    color: #000002;
    font-weight: 700;
    border-bottom: 1px solid #000002;
}

.map-search-right .map-accordion-group-filter a:first-child {
    position: relative;
    float: left;
}

.map-search-right .map-accordion-group-filter a:first-child:after {
    content: '';
    position: absolute;
    height: 24px;
    width: 2px;
    background: #a7aaaa;
    top: 5px;
}

.map-search-right .map-accordion-group-filter a:last-child {
    font-size: 16px;
    padding: 0 5px 0px 2px;
    color: #bf2349;
    float: left;
    margin-top: 3px;
}

.map-search-right .map-accordion-group-filter a:last-child span:first-child {
    font-size: 16px;
    padding: 0 5px 0px 17px;
    color: #bf2349;
    float: left;
    margin-top: 3px;
}

.map-search-right .map-accordion-group-filter a:last-child span:last-child {
    font-size: 24px;
    padding: 0 5px;
    float: right;
    color: #000000;
}

.map-mobile-filter,
.map-search-right .map-accordion-group-filter.layers {
    display: block;
}

.sidebar-map-content.mobile-view.groups .level2-heading .level2-heading-right ul+span {
    display: block;
}

.map-search-right .map-accordion-group-filter.lists {
    display: block;
}

/*sub group display or none*/
.sidebar-map-content.mobile-view.sub-groups {
    display: block;
}

/*group display or none*/
.sidebar-map-content.mobile-view.groups {
    display: none;
}

/*map display or none*/
#map-content-wrap,
#map-wrapper {
    display: none;
}

/*#sidebar-map{background:transparent;}*/

#map-view-header {
    display: none;
}

#layer-view {
    display: none;
}

/*Subgroup*/
/* .sidebar-map-content.desktop-view{display:none;}     */
.sidebar-map-content.mobile-view.sub-groups .level2-driving-details {
    display: none;
}

.sidebar-map-content.mobile-view.sub-groups .level2-content-inner h5 {
    display: none;
}

.sidebar-map-content.mobile-view.sub-groups .level2-content-inner p.level2-gps {
    display: none;
}

.sidebar-map-content.mobile-view.sub-groups .level2-content {
    padding-bottom: 0 !important;
}

.sidebar-map-content.mobile-view.sub-groups .level2-content-top .level2-driver-details {
    float: none;
}

.sidebar-map-content.mobile-view.sub-groups .level2-content-top .level2-driver-details li:first-child {
    float: left;
}

.sidebar-map-content.mobile-view.sub-groups .level2-content-top .level2-driver-details li:last-child {
    float: right;
}

.sidebar-map-content.mobile-view.sub-groups .level2-content-middle ul li span:first-child {
    margin-right: 0px;
}

.sidebar-map-content.mobile-view.sub-groups .level2-content-top .level2-driver-details li:last-child span {
    padding-right: 0;
}

.sidebar-map-content.mobile-view.sub-groups .level2-content-bottom {
    display: none;
}

.sidebar-map-content.mobile-view.sub-groups .level2-heading .level2-heading-right ul li {
    display: none;
}

.sidebar-map-content.mobile-view.sub-groups .level2-heading .level2-heading-right ul li span {
    font-size: 20px;
}

.sidebar-map-content.mobile-view.sub-groups .level2-content-middle ul li span:nth-child(2) {
    padding-right: 0px;
    padding-left: 2px;
}

.sidebar-map-content.mobile-view.sub-groups .level2-content-middle ul li {
    margin: 0 5px;
}

.sidebar-map-content.mobile-view.sub-groups .level2-heading .level2-heading-right ul li:nth-child(1),
.sidebar-map-content.mobile-view.sub-groups .level2-heading .level2-heading-right ul li:nth-child(2) {
    display: inline-block;
}

.sidebar-map-content.mobile-view.sub-groups .level2-heading .level2-heading-right ul+span {
    display: none;
}

.sidebar-map-content.mobile-view.sub-groups .sub-group-content.sub-group-2 {
    margin-bottom: 5px !important;
}

/*Group*/
.sidebar-map-content.mobile-view.groups .level2-driving-details {
    display: none;
}

.sidebar-map-content.mobile-view.groups .level2-content-inner h5 {
    display: none;
}

.sidebar-map-content.mobile-view.groups .level2-content-inner p.level2-gps {
    display: none;
}

.sidebar-map-content.mobile-view.groups .level2-content {
    padding-bottom: 0 !important;
}

.sidebar-map-content.mobile-view.groups .level2-content-top .level2-driver-details {
    float: none;
}

.sidebar-map-content.mobile-view.groups .level2-content-top .level2-driver-details li:first-child {
    float: left;
}

.sidebar-map-content.mobile-view.groups .level2-content-top .level2-driver-details li:last-child {
    float: right;
}

.sidebar-map-content.mobile-view.groups .level2-content-middle ul li span:first-child {
    margin-right: 0px;
}

.sidebar-map-content.mobile-view.groups .level2-content-top .level2-driver-details li:last-child span {
    padding-right: 0;
}

.sidebar-map-content.mobile-view.groups .level2-content-bottom {
    display: none;
}

.sidebar-map-content.mobile-view.groups .level2-heading .level2-heading-right ul li {
    display: none;
}

.sidebar-map-content.mobile-view.groups .level2-heading .level2-heading-right ul li span {
    font-size: 20px;
}

.sidebar-map-content.mobile-view.groups .level2-heading .level2-heading-right ul li:nth-child(1),
.sidebar-map-content.mobile-view.groups .level2-heading .level2-heading-right ul li:nth-child(2) {
    display: inline-block;
}


/*Screen 2*/
.sidebar-map-content-wrap {
    display: block;
    /*background:#ffffff;*/
    /*height:300px;*/
}

#map-content-wrap {
    z-index: 0;
    height: inherit !important;
}

.map-location-7 {
    position: absolute;
    left: 48%;
    top: 290px;
}

/* .map-location-1,.map-location-2,.map-location-3,.map-location-4,.map-location-5,.map-location-6,.map-location-8{display: none;}     */
/* .map-locator a ul li:last-child{display:none;} */
/* .location-detail-box .level2-heading .level2-heading-right ul li{display:none;} */
.location-detail-box .level2-heading .level2-heading-right ul li:nth-child(1),
.location-detail-box .level2-heading .level2-heading-right ul li:nth-child(2) {
    display: inline-block;
}

/* .location-detail-box .level2-content-top .level2-driver-details{float:none;}  */
.location-detail-box .level2-content-top .level2-driver-details li:first-child {
    float: left;
}

.location-detail-box .level2-content-top .level2-driver-details li:last-child {
    float: right;
}

.location-detail-box .level2-content-middle ul li span:first-child {
    margin-right: 0px;
    padding-left: 0;
}

.location-detail-box .level2-content-top .level2-driver-details li:last-child span {
    padding-right: 0;
}

/* .location-detail-box .level2-content-top-inner {margin-bottom:10px;} */
.location-detail-box .level2-content-middle ul li span:nth-child(2) {
    padding-right: 10px;
    padding-left: 2px;
}

/* .location-detail-box .level2-driving-details{display:none;} */
.location-detail-box .level2-content-inner h5 {
    display: none;
}

/* .location-detail-box .level2-content-inner p.level2-gps{display:none;} */
.location-detail-box .level2-heading .level2-heading-right ul+a {
    display: none;
}

/* .location-detail-box .level2-content-middle .panel-title ul li:last-child{display:none;} */
.location-detail-box .level2-content-middle .panel-title ul {
    font-size: 11px;
}

/*Screen 3*/

.map-search-right .devices-link {
    float: left;
    cursor: pointer;
}

.map-search-right .devices-link span:first-child,
.map-search-right .trip-back-link span:first-child,
.map-search-right .trip-detail-back-link span:first-child {
    transform: rotate(90deg);
    margin-right: 10px;
    color: #75787b;
    font-size: 19px;
}

.map-search-right .trip-view-bottom .devices-link span:first-child {
    transform: rotate(0);
}

.map-search-right .trip-view-bottom .trip-date {
    float: right;
    font-size: 17px;
}

.map-search-right .devices-link span:last-child,
.map-search-right .trip-back-link span:last-child,
.map-search-right .trip-detail-back-link span:last-child,
.map-search-right .trip-heading {
    color: #000002;
    font-size: 17px;
    font-weight: 700;
}

.map-search-right .history {
    float: right;
    width: 40px;
}

.map-search-right .history a span {
    font-size: 20px;
    color: #75787b;
}

.map-search-right #map-history-link span.up-arrow {
    width: 20px;
    height: 20px;
    background: #bf2349;
    padding: 0;
    transform: rotate(45deg);
    margin: -23px -11px -14px 0;
    float: right;
    display: block;
    -moz-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -o-transform: rotate(45deg);
}

.map-search-right #map-history-link {
    width: 100%;
    background: #bf2349;
    position: absolute;
    left: 0;
    right: 0;
    top: 47px;
    padding: 20px 20px 50px 20px;
    z-index: 9999;
}

.map-search-right #map-history-link li {
    border-bottom: 1px solid #000002;
    padding-left: 20px;
    padding-top: 5px;
}

.map-search-right #map-history-link li:first-child {
    text-transform: uppercase;
    border-bottom: 2px solid #000002;
    padding-left: 0;
}

.map-search-right #map-history-link li a {
    color: #ffffff;
}

.map-search-right .trip-back-link,
.map-search-right .trip-detail-back-link {
    float: left;
    cursor: pointer;
}

.map-search-right .trip-heading {
    float: right;
}

#trip-view-content {
    padding: 5px;
    overflow: hidden;
    bottom: -4px;
    z-index: 9999;
    width: 100%;
}

.trip-view-content-top-box {
    background: #ffffff;
    padding: 10px;
    width: 48%;
    float: left;
    margin: 4px;
}

.trip-view-content-top-box .trip-box-top span,
.trip-view-content-top-box .trip-box-top h4 {
    float: left;
    margin-top: 0;
    margin-bottom: 0;
}

.trip-view-content-top-box .trip-box-top span {
    color: #bf2349;
}

.trip-view-content-top-box .trip-box-top h4 {
    margin-left: 10px;
    font-size: 14px;
    font-weight: 700;
}

.trip-view-content-top-box .trip-box-bottom {
    font-size: 30px;
}

.trip-view-content-top-box .trip-box-bottom span {
    font-size: 16px;
    margin-left: 5px;
    margin-right: 5px;
}

.trip-view-content-bottom .trip-bottom-item {
    background: #ffffff;
    font-size: 16px;
    font-weight: 700;
}

/* .trip-view-content-bottom .trip-bottom-item:nth-child(1) > div:first-child{background:#7db642;display:inline-block;float:left;}
    .trip-view-content-bottom .trip-bottom-item:nth-child(2) > div:first-child{background:#be2852;display:inline-block;float:left;} */
.numberRed {
    background: #be2852;
    display: inline-block;
    float: left;
}

.numberGray {
    background: #909697;
    display: inline-block;
    float: left;
}

.numberGreen {
    background: #7db642;
    display: inline-block;
    float: left;
}

/* .trip-view-content-bottom .trip-bottom-item:nth-child(3) > div:first-child{background:#909697;display:inline-block;float:left;}
    .trip-view-content-bottom .trip-bottom-item:nth-child(1) > span:first-child{background:#7db642;}
    .trip-view-content-bottom .trip-bottom-item:nth-child(2) > span:first-child{background:#be2852;}
    .trip-view-content-bottom .trip-bottom-item:nth-child(3) > span:first-child{background:#909697;} */
.trip-view-content-bottom .trip-bottom-item .trip-bottom-item-number {
    color: #ffffff;
    font-size: 18px;
    width: 34px;
    text-align: center;
    height: 34px;
    padding: 5px;
}

.trip-view-content-bottom .trip-bottom-item .trip-bottom-item-middle {
    display: inline-block;
    width: 50%;
}

.trip-view-content-bottom .trip-bottom-item .trip-bottom-item-last {
    float: right;
    margin-top: 3px;
    margin-right: 3.5%;
}

.trip-view-content-bottom .trip-bottom-item .trip-bottom-item-time {
    margin: 0 7px;
    float: left;
    margin-top: 5px;
}

.trip-view-content-bottom .trip-bottom-item .trip-bottom-item-content {
    width: 64%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    float: left;
    margin-top: 5px;
}

.trip-view-content-bottom .trip-bottom-item .trip-bottom-item-duration {
    margin-left: 10px;
}

.trip-view-content-bottom .trip-bottom-item .trip-bottom-item-duration>span:first-child {
    color: #75787b;
}

.trip-view-content-bottom .trip-bottom-item .trip-bottom-item-duration>span:last-child {
    margin-left: 10px;
}

.trip-view-content-bottom .trip-bottom-item .trip-bottom-item-duration>span:last-child span {
    margin-left: 5px;
}

#trip-view-detail .trip-view-top .trip-heading {
    margin-right: 20px;
}

#trip-view-detail .trip-view-bottom {
    padding: 0 !important;
}

#trip-view-detail .trip-view-bottom .trip-detail-number {
    color: #ffffff;
    padding: 8px;
    height: 34px;
    width: 34px;
    text-align: center;
    font-size: 18px;
    font-weight: 700;
}

#trip-view-detail .trip-view-bottom .trip-detail-time {
    color: #3b3e40;
    font-size: 17px;
    width: 57%;
    padding-left: 5px;
    font-weight: 700;
}

#trip-view-detail .trip-view-bottom .trip-detail-duration {
    display: flex;
    float: right;
    padding: 8px 5px;
    margin-right: 0.5%;
}

#trip-view-detail .trip-view-bottom .trip-detail-duration>span:first-child {
    color: #75787b;
    font-size: 16px;
}

#trip-view-detail .trip-view-bottom .trip-detail-duration>span:last-child {
    margin-left: 10px;
    font-size: 16px;
    font-weight: 700;
}

#trip-view-detail .trip-view-bottom .trip-detail-duration>span:last-child span {
    margin-left: 5px;
}

#trip-view-detail-content {
    bottom: 52px;
    left: 0;
    right: 0;
    background: #ffffff;
    z-index: 9999;
}

#trip-view-detail-content ul {
    padding: 10px 10px 10px 20px;
    background: #f4f4f3;
    width: 100%;
    margin: 0;
}

#trip-view-detail-content ul li {
    display: inline;
    margin-right: 30px;
}

#trip-view-detail-content ul li span:first-child {
    padding: 0 5px;
    font-size: 20px;
    position: relative;
    top: 3px;
}

#trip-view-detail-content ul li span {
    color: #75787b;
    font-size: 16px;
}

#trip-view-detail-content .trip-view-detail-content-bottom {
    padding: 10px 30px;
}

#trip-view-detail-content .trip-view-detail-content-bottom .detail-content-bottom-heading,
#trip-view-detail-content .trip-view-detail-content-bottom .detail-content-bottom-inner-left {
    color: #3b3e40;
    font-size: 15px;
}

#trip-view-detail-content .trip-view-detail-content-bottom .detail-content-bottom-inner-left {
    width: 100%;
}

#trip-view-detail-content .trip-view-detail-content-bottom .detail-content-bottom-inner-right {
    color: #000002;
    font-size: 15px;
    width: calc(100% - 120px);
}

#trip-view-detail-content .trip-view-detail-content-bottom .detail-content-bottom-inner-left,
#trip-view-detail-content .trip-view-detail-content-bottom .detail-content-bottom-inner-right {
    float: left;
}

#trip-view-detail-content .trip-view-detail-content-bottom .detail-content-bottom-inner-right {
    margin-left: 20px;
}

.mobile-none {
    display: none !important;
}

.map-left-menu {
    /*display:block;*/
    margin-top: 132px;
    padding-top: 0;
    width: 100%;
}

.map-left-menu ul li {
    text-align: left;
    position: relative;
}

.map-left-menu ul li:not(:first-child) {
    padding: 10px 0;
    margin-top: 5px;
}

.map-left-menu ul li:not(:first-child):after {
    position: absolute;
    content: '';
    background: #a7aaaa;
    height: 2px;
    width: 96%;
    left: 2%;
    bottom: -5px;
}

.map-left-menu ul li:first-child {
    background: #e9eae9;
    border-bottom: 2px solid #c0c1c0;
    margin-bottom: 20px;
}

.map-left-menu ul li:first-child a {
    width: 100% !important;
}

.map-left-menu ul li a span:first-child {
    margin-left: 10px;
    display: inline-block;
    color: #75787b;
}

.map-left-menu ul li span {
    font-size: 19px;
    padding-left: 0;
}

.map-left-menu ul li span.layers-close-link {
    font-size: 10px;
    float: right;
    margin-right: 20px;
    padding-top: 10px;
}

.map-left-menu ul li a {
    display: inline-block;
}

.map-left-menu ul li a:nth-child(1) {
    width: calc(100% - 50px)
}

.map-left-menu ul li a:nth-child(1) span.number-of-points {
    float: right;
    padding-top: 7px;
    font-size: 16px;
}

.map-left-menu ul li a:nth-child(2) {
    width: 15px;
}

.map-left-menu ul li a:nth-child(2) span:first-child {
    width: 15px;
    margin-left: 10px;
    transform: rotate(90deg);
    position: relative;
    top: -10px;
}

.map-left-menu ul li a span.left-menu-text {
    font-size: 16px;
    padding-left: 10px;
    position: relative;
    top: -3px;
    font-weight: 700;
}

.map-left-menu ul li a span:first-child {
    padding: 4px 2px 2px;
    width: 33px;
    margin-top: 4px;
}

.map-left-menu ul li:first-child a.active span:first-child,
.map-left-menu ul li:first-child a:hover span:first-child {
    background: transparent;
    color: #75787b;
}

.layer-details {
    background: #d0d3d3;
}

.layer-details-heading {
    padding: 20px 10px 5px 10px;
    display: block;
}

.layer-details-heading-left,
.layer-details-heading-right {
    float: left;
}

.layer-details-heading-right .select-box {
    display: block;
}

.layer-details-heading-right .select-box {
    display: inline-block;
    position: relative;
}

.layer-details-heading-right .select-box .select {
    -moz-appearance: none;
    -webkit-appearance: none;
    position: static;
    cursor: pointer;
    display: inline-block;
    color: #808080;
    line-height: 45px;
    font-size: 12px;
    width: 100%;
    height: 40px;
}

.layer-details-heading-right .select-box .select select {
    width: 75%;
    -moz-appearance: none;
    -webkit-appearance: none;
    font-size: 16px;
    padding: 0px 12px;
    line-height: 1.2;
    height: 40px;
    opacity: 0;
}

.layer-details-heading-right .select-box .select .styledSelect {
    background: url('https://staticcontent.inelcan.com/img/colapse_down.svg') no-repeat 60% 14px;
    font-size: 14px;
    color: #000000;
    width: 100px;
    top: 0;
}

.layer-details-heading-left {
    width: 60%;
}

.layer-details-heading-left>span {
    transform: rotate(90deg);
    margin-right: 10px;
    color: #75787b;
    font-size: 19px;
}

.layer-details-heading-left a span:nth-child(1) {
    background: #bf2349;
    border-radius: 50%;
    color: #ffffff;
    padding: 6px 8px 3px;
    font-size: 18px;
    margin-right: 5px;
}

.layer-details-heading-left a span:nth-child(2) {
    font-size: 16px;
    font-weight: 700;
}

.layer-details-heading-right {
    width: 40%;
}

.layer-details-content {
    margin: 20px 5px 0 5px;
    max-height: 400px;
    overflow-y: scroll;
    padding-bottom: 30px;
}

.layer-details-content .panel {
    border-radius: 0;
}

.layer-details-content .panel-heading {
    border-bottom: 4px solid #a8a9ad;
    padding: 10px;
}

.layer-details-content .layer-accordion-heading-left {
    float: left;
    color: #000002;
    font-weight: 700;
}

.layer-details-content .layer-accordion-heading-right {
    float: right;
    color: #75787b;
    font-size: 15px;
}

.layer-details-content .layer-accordion-heading-right span {
    margin-left: 10px;
    font-size: 20px;
}

.layer-details-content .panel-body {
    padding: 15px 10px 0 10px;
}

.layer-details-content .panel-body ul li {
    border-bottom: 1px solid #a7aaaa;
    padding: 10px 0;
}

.layer-accordion-content-top .layer-accordion-content-top-left {
    float: left;
    color: #000002;
    font-size: 16px;
    font-weight: 700;
}

.layer-accordion-content-top .layer-accordion-content-top-right {
    float: right;
    color: #75787b;
    font-size: 15px;
}

.layer-details-content .collapsed .layer-accordion-heading-right span i {
    transform: rotate(-90deg);
}

.layer-accordion-content-bottom {
    color: #000002;
    font-size: 16px;
    padding-left: 25px;
}

.layer-accordion-heading-left .check-container {
    display: inline-block;
    margin-bottom: 16px;
}

.layer-accordion-content-top-left .check-container {
    display: inline-block;
    margin-bottom: 16px;
}

.layer-accordion-content-top-left .check-container span {
    padding-right: 0;
}

.point-detail {
    background: #ffffff;
    position: fixed;
    bottom: 57px;
    left: 5px;
    right: 5px;
    padding: 10px;
}

.point-detail span.icon-map {
    color: #bf2349;
}

.point-detail .layer-accordion-content-bottom {
    padding-left: 20px;
}

.pointer-point-detail {
    position: relative;
    z-index: 1;
}

.pointer-point-detail img {
    position: absolute;
    left: 45%;
    top: 250px;
}

/*#map-wrapper{z-index:-1;}*/
.main-sidebar-map.active {
    height: 100%;
}



/*------------------For Tab---------------------*/
/* @media only screen and (min-width: 768px) { */
.main-header .navbar {
    border-bottom: 1px solid #8e8e8d;
}

.main-header {
    z-index: 2;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
}

.main-sidebar {
    position: fixed;
    top: 0;
    left: 0;
    padding-top: 105px;
    min-height: 100%;
    width: 260px;
    z-index: 1;
    height: 300px;
    overflow: auto;
    -moz-transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    -ms-transition: all 0.3s;
    transition: all 0.3s;
}

#sidebar ul li a {
    position: relative;
}

#sidebar ul li a:hover {
    background: #bf2349;
    color: #ffffff;
}

#sidebar ul li a:hover:after,
#sidebar ul li a.submenu-link[aria-expanded="true"]:after {
    width: 5px;
    height: 5px;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 8px solid #ffffff;
    position: absolute;
    right: 0;
    z-index: 9999;
    content: '';
}

#sidebar ul li a.submenu-link.collapsed {
    background: transparent;
    color: #ffffff;
}

#sidebar ul li a.submenu-link[aria-expanded="true"],
#sidebar ul li a.submenu-link:hover {
    background: #bf2349;
}

#sidebar ul li a span:nth-child(3) {
    display: none;
}

/*.sidebar-collapse li ul.collapse.in{display:none;}*/
.logo-lg img {
    height: 45px;
}

.main-header .logo {
    -moz-transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    -ms-transition: all 0.3s;
    transition: all 0.3s;
    display: block;
    float: left;
    text-align: center;
    width: 200px;
    height: 105px;
    overflow: hidden;
    z-index: 2;
    position: relative;
    padding: 15px 20px;
    background: #23292D;
}

.main-header .logo .logo-mini {
    display: none;
}

.sidebar-collapse .main-header .logo .logo-mini {
    display: block;
}

.sidebar-collapse .main-header .logo .logo-lg {
    display: none;
}

.logo-mini img {
    height: 45px;
}

.sidebar-collapse .main-header .logo {
    width: 50px;
    padding-left: 0;
    padding-right: 0;
}

.sidebar-collapse .main-menu-toggle {
    -moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}

.navbar-btn {
    float: none;
    position: absolute;
    right: 0;
    top: 0;
}

.sidebar-collapse .navbar-btn {
    left: 0;
    right: inherit;
}

.logo a {
    margin-top: 15px;
    display: block;
}

.icon-4gflota_logo:before {
    color: #ffffff;
    font-size: 150px;
    line-height: 60px;
}

.icon-4gflota_anagram:before {
    color: #ffffff;
    font-size: 42px;
    line-height: 60px;
}

#sidebar.active #homeSubmenu {
    background: #ffffff;
    /* position: absolute; right: -62px; top: 0;*/
}

.mobile-menu-wrapper {
    margin-top: 0px;
    overflow: hidden;
}

#custom-search-input {
    max-width: 280px;
}

#sidebar.active {
    min-width: 40px;
    text-align: center;
}

#sidebar.active .main-menu-toggle .mob-logo {
    display: block;
    max-width: 40px;
    /*margin:30px auto 0; */
}

#sidebar {
    width: 200px;
    background: #23292D;
    color: #fff;
    -moz-transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    -ms-transition: all 0.3s;
    transition: all 0.3s;
}

.sidebar-collapse #sidebar {
    width: 50px;
}

.main-sidebar .icon-next {
    position: absolute;
    left: 35px;
    width: 100%;
    white-space: nowrap;
    opacity: 1;
    -moz-transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    -ms-transition: all 0.3s;
    transition: all 0.3s;
}

.sidebar-collapse .main-sidebar .icon-next {
    opacity: 0;
    -moz-transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    -ms-transition: all 0.3s;
    transition: all 0.3s;
}

#sidebar .sidebar-header {
    display: block;
    text-align: center;
}

/* .sidebar-header.mobile-view{display:none; } */
#sidebar.active .sidebar-header img.desk-logo,
.logout-link,
.mobile-footer {
    display: none !important;
}

.navbar-btn {
    padding: 0 10px;
    color: #878787;
}

#sidebar.active .navbar-btn {
    padding: 0 22px 0 0;
    height: 60px;
}

#sidebar.active .main-menu-toggle i {
    transform: rotate(360deg);
}

#sidebar.active .sidebar-header img.mob-logo {
    max-width: 35px;
}

.menu-dashboard-wrapper {
    display: none;
}

.sidebar-collapse .content-wrapper {
    padding: 105px 40px 50px 90px;
}

.content-wrapper {
    padding: 105px 40px 50px 240px;
}

.sidebar-collapse .edit-dashboard-wrapper {
    padding: 66px 40px 50px 51px;
}

.edit-dashboard-wrapper {
    padding: 66px 40px 50px 201px;
}

.main-sidebar-map {
    z-index: 100;
}

.sidebar-map-content-wrap {
    position: relative;
    overflow: hidden;
    width: 100%;
}

/* .sidebar-map-content-wrap .sidebar-map-content {height:100vh; } */
/* .main-sidebar-map .sidebar-header.mobile-view, .sidebar-map-content.mobile-view.groups, .sidebar-map-content.mobile-view.sub-groups{display:none;} */
/* .map-search-right .map-mobile-filter,.map-search-right .map-accordion-group-filter a:nth-child(2), .map-search-right .map-accordion-group-filter a:first-child:after{display:none;} */
/* .map-search-right .map-accordion-group-filter.layers,.map-search-right .history,.map-search-right .devices-link{display:none;} */

/* #trip-view, #trip-view-content, #trip-view-detail, #trip-view-detail-content{display:none;} */
.desktop-none {
    display: none !important;
}

@media only screen and (min-width: 992px) {

    .dashboard-top-default.select-box .select,
    .dashboard-top-day.select-box {
        /*width:130px;*/
        width: 75%;
    }

    .dashboard-middle-wrap .row {
        display: flex;
    }

    .dashboard-middle-wrap .dashboard-driving-wrap,
    .dashboard-middle-wrap .dashboard-distance-wrap,
    .dashboard-middle-wrap .dashboard-stops-wrap,
    .dashboard-middle-wrap .dashboard-fuel-wrap {
        flex: 1;
        background: #fff;
        margin: 5px;
    }

    .dashboard-filters .dashboard-filters-right,
    .dashboard-filters .dashboard-filters-right .dashboard-menu-right {
        float: right;
    }

    .dashboard-filters-left .dashboard-filter-item {
        min-width: 140px;
        max-width: 140px;
    }

    .dashboard-filters-left .dashboard-filter-item:nth-child(2) {
        margin-right: 5px;
        padding-right: 0 !important;
        padding-left: 0 !important;
    }

    .dashboard-filters-left .dashboard-filter-item:nth-child(3) {
        /*margin-right:10px;*/
        padding-right: 0 !important;
        padding-left: 0 !important;
    }

    .dashboard-filters-left .dashboard-filter-item:nth-child(4) {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .dashboard-content .calculation .pull-left {
        max-width: 90%
    }

    .dashboard-content .dashboard-box .panel .panel-heading .home-dash-heading a .dots-align {
        position: absolute !important;
        top: 0px !important;
        right: -13px;
    }

    .dashboard-content .dashboard-global-wrap .dashboard-box .panel .panel-heading .home-dash-heading a .dots-align {
        position: absolute !important;
        top: -5px !important;
        right: 0;
    }

    .dashboard-filters-left .dashboard-filter-item .dashboard-top-default {
        width: 100%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .main-notifications {
        right: 30% !important;
    }

    #sidebar .sidebar-header {
        text-align: center;
        margin: 10px 0;
        margin-top: 18px;
    }

    #sidebar .sidebar-header img.desk-logo {
        width: 120px;
        margin: 0px auto;
    }

    #sidebar.active .navbar-btn {
        height: 75px !important;
    }

    .dashboard-filters-left {
        margin-bottom: 30px;
    }

    .dashboard-filters-left .dashboard-filter-item {
        margin: 10px 0;
        padding-left: 10px;
    }

    .dashboard-filters-right {
        padding-left: 10px;
    }

    .dashboard-filters-left .select-box {
        width: 75%;
    }

    .dashboard-top-default .styledSelect {
        background: url(https://staticcontent.inelcan.com/img/colapse_down%202.svg) no-repeat 96% 4px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {

    .edit-dashboard-left,
    .edit-dashboard-right {
        width: 100%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
    .dashbord-type-dropdown {
        font-size: 16px;
    }

    .profile-drop-down {
        font-size: 16px;
    }

    .profile-drop-down span {
        margin-right: 5px;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1350px) {
    .edit-dashboard-right .dashboard-state-wrap table tr td:nth-child(1) {
        font-size: 14px;
        vertical-align: inherit !important;
    }

    .edit-dashboard-right .dashboard-state-wrap .panel-body table tr td {
        height: 30px;
    }

    .edit-dashboard-right .dashboard-state-wrap .panel-body table tr td table tr {
        border-bottom: 5px solid #fff;
    }

    .edit-dashboard-right .empty-widget-wrap .panel-body {
        min-height: 277px !important;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .dashboard-filters-left .dashboard-filter-item {
        min-width: 94px;
        max-width: 140px;
    }

    .dashboard-top-default.select-box .select {
        width: 105px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1330px) {
    .dashboard-middle-wrap .calculation .pull-right {
        float: none !important;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1490px) {
    .dashboard-content .dashboard-box .panel .panel-heading .home-dash-heading {
        padding: 5px;
    }

    .dashboard-content .dashboard-box .panel .panel-body {
        padding: 0;
    }

    .dashboard-box .dashboard-box-body {
        padding: 5px;
    }

    .dashboard-content .calculation,
    .global-fleet {
        padding: 5px 5px;
        height: 50px;
    }

    .dashboard-content .dashboard-box .Numbers {
        font-size: 20px;
    }

    .dashboard-content .dashboard-box .Letters {
        font-size: 15px;
    }

    .dashboard-content .dashboard-box .panel .panel-heading .home-dash-heading .home-dash-text {
        font-size: 12px;
        width: calc(100% - 40px);
    }

    .dashboard-state-wrap .dashboard-state-body {
        margin-top: 11px;
    }

    .dashboard-global-body {
        margin-top: -22px;
    }

    .dashboard-content .dashboard-box .panel .panel-heading .home-dash-heading span:first-child {
        width: 20px;
    }
}