/* * {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
	-moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
} */

/* @font-face {
  font-family: 'roboto';
  font-style: normal;
  src: url('../../fonts/Roboto-Regular.ttf'); /* IE9 Compat Modes 
} */


/* html, body, .wrapper {
  padding: 0;
  margin: 0;
  height: 100%;
  position: relative;
  font-family: 'roboto';
  line-height: 1;
}
.map-wrapper {
  height: 100%;
  position: relative;
}

.wrapper {min-height: 100%; position: relative; } */


/* Controles */
.containerOptions {
  width: 210px;
  background: #fff;
  padding: 15px;
  border-radius: 1px;
  position: absolute;
  z-index: 1000;
  /* height: 385px; */
  left: calc(100% - 210px);
  top: 60px;
  min-height: 336px;
  /* box-shadow: 0px 0px 1px 0px; */
  padding-bottom: 10px;
}

.container-label-options {
  display: flex;
  justify-content: space-between;
  align-content: center;
  margin-left: 3px;
  margin-top: 17px;
  font-size: 14px;
}

.container-label-options>label {
  cursor: pointer;
}

.input {
  margin-bottom: 3px;
  width: 180px;
  padding: 0 0 0 5px;
  border: none;
  position: absolute;
  top: 58px;
}

.displayNone {
  display: none;
}

.h1StyledTop {
  font-size: 11px;
  font-weight: 800;
  margin-bottom: 10px;
}

.h1StyledBottom {
  margin-top: 20px;
  font-size: 11px;
  font-weight: 800;
  margin-bottom: 10px;

}

.labelStyled {
  text-align: center;
  color: #000;
  font-size: 11px;
  cursor: pointer;
}

.containerOptions ul {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30px, 1fr));
  grid-gap: 21px;
  padding: 0;
}

.containerOptions li {
  background-color: #fff;
  border-radius: 5px;
  padding: 12px;
  font-size: 15px;
  color: #000;
  border: 1px solid #000;
  margin-bottom: 5px;
  list-style: none;
}

.containerOptions .aws-sld__bullets button {
  width: 5px;
  height: 5px;

}

button:focus {
  outline: 0;
}

/* HIDE RADIO */
/* [type=checkbox] {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
} */

/* IMAGE STYLES */
[type=checkbox]+img {
  cursor: pointer;
  border: 2px solid transparent;
  border-radius: 5px;
}

/* CHECKED STYLES */
/* [type=checkbox]:checked + img {
  border: 2px solid red;
  border-radius: 5px;
} */


.awssld__controls__arrow-right,
.awssld__controls__arrow-left {
  --organic-arrow-height: 15px;
  --organic-arrow-color: #fff;
}

.awssld__bullets {
  position: absolute;
  bottom: -25px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center
}

.awssld__bullets button {
  width: 6px;
  height: 6px;
  bottom: -16px;
}

.effect-17 {
  border: 0;
  padding: 4px 0;
  border-bottom: 1px solid #ccc;
  background-color: transparent;
}

.effect-17~.focus-border {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 2px;
  background-color: #3399FF;
  transition: 0.4s;
}

.effect-17:focus~.focus-border,
.has-content.effect-17~.focus-border {
  width: 100%;
  transition: 0.4s;
  left: 0;
}


.awssld div {
  --slider-height-percentage: 55%;
  --content-background-color: #4287f5;
  color: #fff;
}

.btn-options {
  background: none;
  border: 0;
  color: inherit;
  /* cursor: default; */
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  -webkit-user-select: none;
  /* for button */
  -webkit-appearance: button;
  /* for input */
  -moz-user-select: none;
  -ms-user-select: none;
  outline: none;
  cursor: pointer;

}

.btn-options2:hover,
.btn-options2:active {
  background-color: #32a852 !important;
  color: white !important;
  text-decoration: none;
  border-color: #32a852 !important;
}

.btn-options2 {
  border: none;
  padding: 5px;
  text-align: center;
  display: inline-block;
  margin: 4px 2px;
  cursor: pointer;
  min-width: 30px;
  min-height: 30px;
  border-radius: 3px;
  background-color: transparent;
  color: #fff !important;
  border: 2px solid #fff;
  border-radius: 7px;

}

.btn-optionsDC:hover,
.btn-optionsDC:active {
  background-color: #eb4034 !important;
  color: white !important;
  text-decoration: none;
  border-color: #eb4034 !important;
}

.btn-optionsDC {
  border: none;
  padding: 5px;
  text-align: center;
  display: inline-block;
  margin: 4px 2px;
  cursor: pointer;
  min-width: 30px;
  min-height: 30px;
  border-radius: 3px;
  background-color: transparent;
  color: #fff !important;
  border: 2px solid #fff;
  border-radius: 7px;
}


.divContent {
  display: flex;
  justify-content: center;
  align-items: center;
}

.divTitleOptions {
  /* max-width: 38%;
  float: left;
  line-height: 4.4; */
  margin-left: 30px;
  flex: 30%;
  word-wrap: break-word;
  overflow: hidden;
  white-space: nowrap;
  white-space: initial;
  max-width: 90px;
  min-width: 30px;
}

.divButtonOptions {
  /* max-width: 38%;
  float: right;
  text-align: center; */
  grid-gap: 25px;
  flex: 10%;
  margin-top: 2px;
}

input[type='text'],
input[type='number'],
textarea {
  font-size: 16px;
}

.rotate {
  transform: rotate(-45deg);
}

.btn {
  background: #dddddd;
  width: 45px;
  height: 45px;
  color: #000;
  font-size: 20px;
  border: transparent;
  border-radius: 50%;
  cursor: pointer;
  transition: all .3s ease-in-out;
  z-index: 10000;
}

.effect {
  transition: all .3s ease-in-out;
}

button:focus {
  outline: 0;
}

.square {
  background: #dddddd;
  border-radius: 0;
  cursor: pointer;
  height: 45px;
  width: 150px;
  transition: all .3s ease-in-out;
  border: transparent;
  font-size: 18px;
  transition: transform "width .5s ease-in-out";
  z-index: 10000;
  margin-left: -50px;
}


/* Mobile mode */

/* .location-detail-box.panel {
  position: absolute;
  width: 300px;
  -webkit-box-shadow: 4px 4px 0px 0px rgba(217, 217, 217, 0.6);
  -moz-box-shadow: 4px 4px 0px 0px rgba(217, 217, 217, 0.6);
  box-shadow: 4px 4px 0px 0px rgba(217, 217, 217, 0.6);
  border-radius: 0;
  z-index: 1;
  /* height: 30px; 
} */

.panel {
  margin-bottom: auto;
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
  box-shadow: 0 1px 1px rgba(0, 0, 0, .05)
}

/* @media only screen and (max-width: 960px){
  .location-detail-box.panel {
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    right: 0;
    margin: 0 auto;
    visibility: visible;
  }
} */

.location-detail-box.panel {
  position: fixed;
  bottom: 0px;
  width: 100%;
  left: 0;
  right: 0;
  margin: 0 auto;
  visibility: visible;
  transition: height 2s;

}


.widget-container {
  position: absolute;
  top: 0;
  width: 100%;
  height: 0;
  z-index: 7;
}

/* NEW DESIGN */
.widget-container-open {
  width: 360px;
  position: absolute;
  top: 0;
  opacity: 1;
  height: 100%;
  left: 0;
  background-color: rgb(243, 243, 243);
  z-index: 7;
  border: 0;
  display: block;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  transition-duration: 0.3s;
  transform: translateX(0px);
}

.widget-container-close {
  transform: translateX(-360px);
}


.widget-toggle-button {
  display: block;
  width: 50px;
  height: 48px;
  margin-left: -2px;
  border-radius: 2px;
  position: absolute;
  top: 16px;
  left: 100%;
  background: white;
  margin: 0;
  padding: 0;
  border: 0;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  transition: display 0s, display 0.5s linear;
}

@media only screen and (min-width: 320px) and (max-width: 420px) {
  .widget-container-open {
    width: 100%;
    position: absolute;
    top: 0;
    opacity: 1;
    height: 100%;
    left: 0;
    background-color: rgb(243, 243, 243);
    z-index: 7;
    border: 0;
    display: block;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
    transition-duration: 0.3s;
    transform: translateX(0px);
  }

  .widget-container-close {
    transform: translateX(-360px);
    width: 360px;
  }
}

.first-container {
  bottom: 0;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 0;
  width: 100%;

  padding: 10px;
  background: #fff;
  overflow: hidden;
  /* height: auto; 
  width: 100%; 
  margin: auto; 
  background: #fff; 
  padding: 10px; 
  position: absolute; */
  overflow: auto;
}


/* .first-container::-webkit-scrollbar {
  width: 2px;
}

.first-container::-webkit-scrollbar-thumb {
  background: red;
  
} */
.second-container {
  /* background-color: #f8f9fa; */
  /* box-shadow: 0 -1px 2px rgba(0,0,0,0.3); */
  /* margin-top: 268px; */
  padding: 0;
  /* position: absolute; */
  width: 100%;
  /* height: calc(100% - 20%); */

}

.container-options {
  width: 100%;
  margin: 5px auto;
  font-size: 24px;
  text-align: center;
  display: grid;
  gap: 25px 20px;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 50px);
  justify-content: space-between;
  align-content: space-between;
  box-shadow: none;
  display: none;
}

.container-options-open {
  width: 100%;
  margin: 5px auto;
  font-size: 24px;
  text-align: center;
  display: grid;
  gap: 20px 0px;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 50px);
  justify-content: space-between;
  align-content: space-between;
  box-shadow: none;
  color: rgba(0, 0, 0, 0.54);
}

.container-options-layers {
  width: 100%;
  margin: 5px auto;
  font-size: 24px;
  text-align: center;
  display: grid;
  gap: 5px;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(1, 1fr);
  justify-content: left;
  align-content: left;
  box-shadow: none;
  margin-bottom: 0;
}

.label-color {
  white-space: normal;
  color: rgba(0, 0, 0, 0.54);
  width: 79px;
}


/* .container-options>div {
  display: block;
  padding: 10px;
  color: #fff;
  background: #2f89fc;
  border-radius: 3px;
} */

.line {
  width: 310px;
  border-top: 1px solid rgba(0, 0, 0, .2);
  margin: 25px auto;
}

.line-layers-container {
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, .2);
  margin: 10px 0 20px 0;
  display: none;
}

.line-layers-container-open {
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, .2);
  margin: 10px 0 20px 0;
  display: block;
}

.line2 {
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, .2);
  margin: 0px 0 20px 0;
}

.layer-container {
  width: 100%;
  height: auto;
  margin: auto;
  word-wrap: break-word;
  height: 100%;
}

.layer-content {
  padding: 15px 0 15px 0;
}

.slider-container {
  width: 90%;
  height: auto;
  margin: auto;
  word-wrap: break-word;
  height: 100%;
}

.slider {
  width: 200px;
  height: 140px;
  background: #ff8000;
  margin: auto auto 10px;
  border-radius: 3px;
}

.layers-container {
  /* width: 1000px;
  height: auto;
  margin: auto;
  overflow-x: auto;
  white-space: nowrap; */
  overflow-y: hidden;
  overflow-x: auto;
  width: auto;
  height: 85px;
  padding: 10px;
  white-space: nowrap;
}

.layers-container-desktop {

  width: 100%;
  /* height: 85px; */
  padding: 10px;
  white-space: nowrap;

}


.btn-layers {
  display: inline-block;
  border-radius: 60px;
  /* box-shadow: 0px 0px 2px #888; */
  padding: 0.4em 0.5em;
  background: #e7e7e7;
  width: 50px;
  height: 50px;
  font-size: 18px;
  color: rgb(128, 128, 128);
  border: 0;
  cursor: pointer;
}

.mgl {
  margin-right: 16px;
}

.circle-icon-mobile {
  display: inline-block;
  border-radius: 60px;
  /* box-shadow: 0px 0px 2px #888; */
  padding: 0.3em 0.4em;
  background: #ffc0c0;
  font-size: 25px;

}

.h4 {
  text-align: center;
  color: rgba(0, 0, 0, 0.7);
  padding: 10px;
  font-weight: 500;
}

.caret-style {
  font-size: 20px;
  color: rgba(0, 0, 0, 0.5);

  /* margin-right: 5px; */
}

/* @media (min-height: 320px) {
  .widget-container {
    height: 100%;
    margin-top: 0;
  }
} */

@font-face {
  font-family: 'Glyphter';
  src: url('../../fonts/Glyphter.woff');
  /* src: url('./fonts/Glyphter.eot?#iefix') format('embedded-opentype'), url('./fonts/Glyphter.woff') format('woff'), url('./fonts/Glyphter.ttf') format('truetype'), url('./fonts/Glyphter.svg#Glyphter') format('svg'); */
  font-weight: normal;
  font-style: normal;
}

[class*='icon-']:before {
  display: inline-block;
  font-family: 'Glyphter';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

.icon-circles4:before {
  content: '\0041';
}

.icon-close_red:before {
  content: '\0042';
  width: 100%;
}

.icon-colapse_down:before {
  content: '\0043';
}

.icon-dashboard:before {
  content: '\0044';
}

.icon-date:before {
  content: '\0045';
}

.icon-document:before {
  content: '\0046';
}

.icon-drag_line:before {
  content: '\0047';
}

.icon-duplicate:before {
  content: '\0048';
}

.icon-edit:before {
  content: '\0049';
}

.icon-euro_money_red:before {
  content: '\004a';
}

.icon-fuel_station:before {
  content: '\004b';
}

.icon-fullscren:before {
  content: '\004c';
}

.icon-gauge:before {
  content: '\004d';
}

.icon-gauge_grey:before {
  content: '\004e';
}

.icon-globe:before {
  content: '\004f';
}

.icon-help_grey:before {
  content: '\0050';
}

.icon-layers:before {
  content: '\0051';
}

.icon-like_grey:before {
  content: '\0052';
}

.icon-lines4:before {
  content: '\0053';
}

.icon-list:before {
  content: '\0054';
}

.icon-logout:before {
  content: '\0055';
}

.icon-map:before {
  content: '\0056';
}

.icon-map_view:before {
  content: '\0057';
}

.icon-mapCustom:before {
  content: '\0058';
}

.icon-menu_circle:before {
  content: '\0059';
  width: 100%;
}

.icon-menu_mobile_white:before {
  content: '\005a';
}

.icon-navigator_ico:before {
  content: '\0061';
}

.icon-no_gprs:before {
  content: '\0062';
}

.icon-no_gps:before {
  content: '\0063';
}

.icon-organigram:before {
  content: '\0064';
}

.icon-organigram {
  line-height: 1.5;
}

.icon-parking:before {
  content: '\0065';
}

.icon-poiExclamationMark:before {
  content: '\0066';
}

.icon-poiRfid:before {
  content: '\0067';
}

.icon-radar:before {
  content: '\0068';
}

.icon-road_red:before {
  content: '\0069';
}

.icon-road2:before {
  content: '\006a';
}

.icon-rss:before {
  content: '\006b';
}

.icon-save:before {
  content: '\006c';
}

.icon-search:before {
  content: '\006d';
}

.icon-search_grey:before {
  content: '\006e';
}

.icon-share:before {
  content: '\006f';
}

.icon-sheet_grey:before {
  content: '\0070';
}

.icon-square:before {
  content: '\0071';
}

.icon-star:before {
  content: '\0072';
}

.icon-steering_wheel:before {
  content: '\0073';
}

.icon-stop_sign:before {
  content: '\0074';
}

.icon-time:before {
  content: '\0075';
}

.icon-time_red:before {
  content: '\0076';
}

.icon-tool:before {
  content: '\0077';
}

.icon-track:before {
  content: '\0078';
}

.icon-trafficLights:before {
  content: '\0079';
}

.icon-trash:before {
  content: '\007a';
}

.icon-user_blackwhite:before {
  content: '\0030';
}

.icon-user_grey:before {
  content: '\0031';
}

/* .icon-vehicles {
  color: #a5a5a5;
} */

.icon-vehicles:before {
  content: '\0032';
}

.icon-vehicles-content:before {
  content: '\0032';
}

.icon-warning_yellow:before {
  content: '\0033';
}

.icon-wireless_grey:before {
  content: '\0034';
}

.icon-4gflota_anagram:before {
  content: '\0035';
}

.icon-4gflota_logo:before {
  content: '\0036';
}

.icon-add_new_plus:before {
  content: '\0037';
}

.icon-adjust_grey:before {
  content: '\0038';
}

.icon-alert:before {
  content: '\0039';
}

.icon-alert_white:before {
  content: '\0021';
}

.icon-ambulance_grey:before {
  content: '\0022';
}

.icon-arrows_double_grey:before {
  content: '\0023';
}

.icon-bag_grey:before {
  content: '\0024';
}

.icon-bubble_speech:before {
  content: '\0025';
}

.icon-bus_gray:before {
  content: '\0026';
}

.icon-chrono:before {
  content: '\0027';
}



@media (prefers-reduced-motion:reduce) {
  .collapsing {
    transition: .35s ease;
  }
}

.list-addons {
  display: flex;
  justify-content: space-between;
  width: 158px;
}

.color-gray {
  color: #9c9b9b;
}

.panel {
  border: none;
}

.layerActive {
  background: #bf2349;
  color: #fff;
  font-size: 25px;
  line-height: 1;
}

select {
  color: #000;
  color: rgba(0, 0, 0, 0);
  text-shadow: 0 0 0 #000;
  width: 100%;
  padding: 10px;
  padding-left: 0px;
  padding-bottom: 5px;
  border: none;

  -moz-appearance: none;

  -ms-appearance: none;
  -o-appearance: none;

  background: url("../../img/drop-down-arrow.png") no-repeat right center;
  background-size: 10px;
  font-size: 14px;

}

select:focus {
  outline: none;
}

select option {
  color: #000;
}

[type=button],
[type=reset],
[type=submit],
button {
  -webkit-appearance: none;
}

.line-select {
  width: 98%;
  margin-left: 4px;
  margin-top: 2px;
  border-top: 1px solid rgba(0, 0, 0, 0.3);
}

.MuiTypography-body1 {
  font-size: 15px !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}

.MuiFormControlLabel-labelPlacementStart {

  margin-left: 4px !important;
  margin-right: -11px;
  justify-content: space-between !important;

}

.MuiFormGroup-row {
  flex-direction: column !important;
}

/*
.MuiFormGroup-root {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between !important;
} */

.MuiSwitch-root {

  width: 58px;
  height: 38px;
  display: inline-flex;
  padding: 12px;
  z-index: 0;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  flex-shrink: 0;
  vertical-align: middle;
  justify-content: space-between !important;


}

.lineBottom {
  margin-bottom: 8px;
  font-size: 14px;
}

.tableProducts thead tr,
td {
  font-size: 11px;
  font-weight: 400 !important;
  padding: 5px 5px 5px 0;
}

.tableProducts tbody {
  color: #808081 !important
}


/* Spinner 3Balls Scale */
.sp-3balls,
.sp-3balls:before,
.sp-3balls:after {
  border-radius: 50%;
  background-color: rgb(191, 35, 73);
  width: 18px;
  height: 18px;
  transform-origin: center center;
  display: inline-block;
  z-index: 1000;
  top: 450px;
}

.sp-3balls {
  position: relative;
  background-color: rgba(rgb(248, 245, 245), 1);
  opacity: 1;
  -webkit-animation: spScaleAlpha 1s infinite linear;
  animation: spScaleAlpha 1s infinite linear;
}

.sp-3balls:before,
.sp-3balls:after {
  content: '';
  position: relative;
  opacity: 0.40;
}

.sp-3balls:before {
  left: 30px;
  top: 0px;
  -webkit-animation: spScaleAlphaBefore 1s infinite linear;
  animation: spScaleAlphaBefore 1s infinite linear;
}

.sp-3balls:after {
  left: -30px;
  top: -21px;
  -webkit-animation: spScaleAlphaAfter 1s infinite linear;
  animation: spScaleAlphaAfter 1s infinite linear;
}

@-webkit-keyframes spScaleAlpha {
  0% {
    opacity: 1;
  }

  33% {
    opacity: 0.35;
  }

  66% {
    opacity: 0.55;
  }

  100% {
    opacity: 1;
  }
}

@keyframes spScaleAlpha {
  0% {
    opacity: 1;
  }

  33% {
    opacity: 0.35;
  }

  66% {
    opacity: 0.55;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes spScaleAlphaBefore {
  0% {
    opacity: 0.35;
  }

  33% {
    opacity: 1;
  }

  66% {
    opacity: 0.55;
  }
}

@keyframes spScaleAlphaBefore {
  0% {
    opacity: 0.25;
  }

  33% {
    opacity: 1;
  }

  66% {
    opacity: 0.25;
  }
}

@-webkit-keyframes spScaleAlphaAfter {
  33% {
    opacity: 0.25;
  }

  66% {
    opacity: 1;
  }

  100% {
    opacity: 0.25;
  }
}

@keyframes spScaleAlphaAfter {
  33% {
    opacity: 0.25;
  }

  66% {
    opacity: 1;
  }

  100% {
    opacity: 0.25;
  }
}

.svgPinIconYellow {
  fill: #eee411 !important;
}

.svgPinIconGreen {
  fill: #3ba935 !important;
}

/* .cuadrado {
  position: absolute;
  right: -33px;
  top: -55px;
  width: 100px;
  height: 50px;
  background: gray;
  padding: 5px;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
} */

.popup1 {
  position: absolute;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: width 2s;
}

.popup1 .popuptext {
  width: 140px;
  background-color: rgb(119, 119, 119);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 8px 0;
  position: absolute;
  z-index: 1;
  left: -61px !important;
  top: -69px;
  height: 27px;
}

.popup1 .popuptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: rgb(119, 119, 119) transparent transparent transparent;
}

.level2-content-inner>p {
  margin-bottom: 3px !important;
}

.MuiFormControlLabel-labelPlacementStart {
  margin-left: 0px !important;
}

label {
  display: inline-block;
  margin-bottom: 0px !important;
}

.clearfix::before {
  display: table;
  content: " ";
}






/* Popup container */
.popup {
  position: relative;
  display: inline-block;
  cursor: pointer;
  transition: width 2s;
}

/* The actual popup (appears on top) */
/* .popuptext {
  width: 160px;
  background-color: #bf2349;
  color: #fff;
  
  border-radius: 6px;
  padding: 0;
  position: absolute;
  z-index: 100;
  bottom: 100%;
  left: 71.4%;
  margin-left: -80px;
} */

.popuptext {
  width: 100%;
  background-color: #bf2349;
  color: #fff;
  padding: 0;
  position: static;
  z-index: 100;
  bottom: 25.9% !important;
  left: 0;
}

.popuptextTodayYesterday {
  width: 100%;
  background-color: #bf2349;
  color: #fff;
  padding: 0;
  position: static;
  z-index: 100;
  bottom: 25.9% !important;
  left: 0;
}

.popuptextTodayYesterday::after {
  content: "";
  position: absolute;
  /* top: 100%; */
  left: 79.5%;
  margin-left: -3px;
  border-width: 5px;
  border-style: solid;
  border-color: #bf2349 transparent transparent transparent;
  z-index: 1000;
}


/* Popup arrow */
.popuptext::after {
  content: "";
  position: absolute;
  /* top: 100%; */
  left: 79.5%;
  margin-left: -3px;
  border-width: 5px;
  border-style: solid;
  border-color: #bf2349 transparent transparent transparent;
  z-index: 1000;
}

/* Toggle this class when clicking on the popup container (hide and show the popup) */
.popuptext .show {
  visibility: visible;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s
}

/* Add animation (fade in the popup) */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}



.list-historical {
  list-style: none;
  padding: 10px;

}

.list-historical :first-child {
  text-align: center;
  border-bottom: 1px solid #fff;
  padding-bottom: 10px;
  margin-bottom: 5px;
}

.list-historical :nth-child(2) {
  border-bottom: 1px solid #fff;
  padding-bottom: 10px;
  margin-bottom: 5px;
  cursor: pointer;
}

.list-historical :nth-child(3) {
  border-bottom: 1px solid #fff;
  padding-bottom: 10px;
  margin-bottom: 5px;
  cursor: pointer;
}

.list-historical :nth-child(4) {

  cursor: pointer;
}


/* #mainLoadingPage {
  background: url('../../callToApi/map-marker.gif') no-repeat center center;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 9999999;
} */

.pin {
  width: 70px;
  height: 70px;
  border-radius: 50% 50% 50% 0;
  background: #be2852;
  position: absolute;
  /* transform: rotate(-45deg); */
  left: 44%;
  top: 50%;
  margin: -30px 0 0 -4px;
}

.pin:after {
  content: "";
  width: 34px;
  height: 34px;
  margin: 8px 0 0 8px;
  background: #e6e6e6;
  position: absolute;
  border-radius: 50%;
  top: 8px;
  right: 16px;
}

.bounce {
  animation-name: bounce;
  animation-fill-mode: both;
  animation-duration: 1s;
}

.bounceImg {
  animation-name: bounceImg;
  animation-fill-mode: both;
  animation-duration: 1s;
}

.pulse {
  background: #d6d4d4;
  border-radius: 50%;
  height: 64px;
  width: 64px;
  position: absolute;
  left: 44%;
  top: 50%;
  margin: 22px 0px 0px -1px;
  transform: rotateX(55deg);
  z-index: -2;
}

.pulse:after {
  content: "";
  border-radius: 50%;
  height: 84px;
  width: 84px;
  position: absolute;
  margin: -7px 0 0 -9px;
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 0;
  box-shadow: 0 0 1px 2px #be2852;
  animation-delay: 1.1s;
}

.gm-style .gm-style-iw-c {
  position: absolute;
  box-sizing: border-box;
  overflow: hidden;
  top: -35px;
  left: 0;
  transform: translate(-50%, -100%);
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.3);
}

.gm-style .gm-style-iw-t::after {

  background: linear-gradient(45deg, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0) 51%, rgba(255, 255, 255, 0) 100%);
  box-shadow: -2px 2px 2px 0 rgba(178, 178, 178, .4);
  content: "";
  height: 15px;
  left: 0;
  position: absolute;
  top: -35px;
  transform: translate(-50%, -50%) rotate(-45deg);
  width: 15px;
}

@keyframes pulsate {
  0% {
    transform: scale(0.1, 0.1);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: scale(1.2, 1.2);
    opacity: 0;
  }
}

@keyframes bounce {
  0% {
    opacity: 0;
    transform: translateY(-1000px) rotate(-45deg);
  }

  60% {
    opacity: 1;
    transform: translateY(30px) rotate(-45deg);
  }

  80% {
    transform: translateY(-10px) rotate(-45deg);
  }

  100% {
    transform: translateY(0) rotate(-45deg);
  }
}

@keyframes bounceImg {
  0% {
    opacity: 0;
    transform: translateY(-1000px);
  }

  60% {
    opacity: 1;
    transform: translateY(30px);
  }

  80% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(0);
  }
}