/* @import url('https://fonts.googleapis.com/css?family=Lato:400,700,900'); */

html, body, .map-wrapper {
    height: 100%;
}

.border-none{border:none !important;}
.no-padding {padding-left: 0; padding-right: 0; }
.no-padding-left {padding-left:0; }
.no-padding-right {padding-right:0; }
.padding-left-5{padding-left:5px; }
.padding-right-5{padding-right:5px; }
.red-icon{color:#CD0438 !important; }
.yellow-icon{color:#f4ab2d !important; }

/*.select-box{width: 350px;}*/
.select-box .select{cursor:pointer; display:inline-block; position:relative; color:#808080; line-height: 25px; font-size:12px; width:100%; height: 30px;}
.map-mobile-filter .select{cursor:pointer; display:inline-block; color:#808080; line-height: 45px; font-size:12px; width:100%; height: 30px;}
select.selectArrow{-moz-appearance: none; appearance: none; -webkit-appearance: none; border: 1px solid #a8a8a8; color: #888; cursor: pointer; display: inline-block; width: 100%; border: 0; color: #323a45;}
/* .styledSelect{background: url(../images/down-arrow.png) no-repeat 100% 8px; position: absolute; top: 6px; right: 0; bottom: 0; left: 1px; background-color: #FFFFFF; padding: 0 35px 0 0; font-size: 20px; color: #323a45; width: 98%; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; padding-left: 9px;} */
.styledSelect:hover{background-position: 100% -18px; color: #c0234a;}
.select-box .options{background-color:#f6f6f6; color:#808080; display:none; position:absolute; right:0; left:0px; padding-left:0;margin:0 0 0 0; line-height:25px; list-style:none; width:100%; top:38px; z-index: 1; box-shadow: 0 0 15px rgba(0,0,0,0.5);}
.map-mobile-filter .select-box .options{background-color:#f6f6f6; color:#808080; display:none; position:absolute; right:0; left:0px; padding-left:0;margin:0 0 0 0; line-height:25px; list-style:none; width:100%; top:50px; z-index: 1; box-shadow: 0 0 15px rgba(0,0,0,0.5);}
.map-mobile-filter .select-box .options li:first-child{display:none;}
.select-box .options li,.map-mobile-filter .select-box .options li{margin:0 0 0 0; display:block; clear:both; padding:5px 15px; color:#323a45; font-size: 15px;width:100% !important;}
.select-box .options li:last-child, .map-mobile-filter .select-box .options li:last-child{border-bottom: 0;}
.link-content ul li .select-box .options li, .map-mobile-filter .select-box .options li{padding: 10px 15px; margin-bottom: 0; transition: all 0.5s ease; -moz-transition: all 0.5s ease; -webkit-transition: all 0.5s ease; -o-transition: all 0.5s ease; -ms-transition: all 0.5s ease; word-wrap: break-word;}
.select-box .options li:hover, .map-mobile-filter .select-box .options li:hover{background: #eeeeee; color: #c0234a; transition: all 0.5s ease; -moz-transition: all 0.5s ease; -webkit-transition: all 0.5s ease; -o-transition: all 0.5s ease; -ms-transition: all 0.5s ease;width:100% !important;}


.dashboard-top-default.select-box .select select, .dashboard-top-day.select-box .select select{border-radius:0;border:none;padding:0;}
.dashboard-top-default.select-box .select select{-moz-appearance: none; -webkit-appearance: none;background:transparent;box-shadow:none;font-size:16px;}
.dashboard-top-day .styledSelect{background: url('https://staticcontent.inelcan.com/img/colapse_down.svg') no-repeat 93% 8px;background-color:#ffffff; font-size:15px;width:100%;top:0;height:34px;}
.dashboard-top-default .styledSelect{background: url('https://staticcontent.inelcan.com/img/colapse_down%202.svg') no-repeat 80% 0px; font-size:15px;}

.dashboard-top-default.select-box .select select{ opacity: 0; }
a, a:hover, a:focus, a.active{
    color: inherit;
    transition: 0.5s;
    -moz-transition: 0.5s;
    -webkit-transition: 0.5s;
    -ms-transition: 0.5s;
    -o-transition: 0.5s;
    text-decoration: none;
}
[data-toggle~="collapse"] {
    cursor: pointer;
}
 .navbar {
     padding: 18px 0px;
     background: #fff;
     border: none;
     border-radius: 0;
     margin-bottom: 0;
     /* -webkit-box-shadow: 5px 7px 40px -2px rgba(173, 171, 173, 0.7);
     -moz-box-shadow: 5px 7px 40px -2px rgba(173, 171, 173, 0.7);
     box-shadow: 5px 7px 40px -2px rgba(173, 171, 173, 0.7); */
     height:65px;
}
 .navbar-btn {
     box-shadow: none;
     outline: none !important;
     border: none;
     background: none;
     float: right;
     padding: 0 20px;
     font-size: 20px;
     margin: 0;
}

 .main-notifications {
     position: absolute;
     right: 40%;
}
 .main-notifications>li a{
     padding:5px;
}
 .line {
     width: 100%;
     height: 1px;
     border-bottom: 1px dashed #ddd;
     margin: 40px 0;
}
 i, span {
     display: inline-block;
}

.main-sidebar.ps-container.ps-active-x > .ps-scrollbar-x-rail {
    display: none;
}
/* SIDEBAR STYLE */
 #sidebar.active .sidebar-header img {
     display: none;
}
 #sidebar.active .main-menu-toggle{
     transform:rotate(180deg);
}
 #sidebar.active .sidebar-header strong,#sidebar.active .sidebar-header img.mob-logo {
     display: block;
     margin:30px auto 0;
}
 #sidebar ul li a {
     text-align: left;
}
 #sidebar.active ul li a {
    /* padding: 10px;
     text-align: center;
     font-size: 0.85em;*/
}
 #sidebar.active ul li a i {
     margin-right: 0;
     display: block;
     font-size: 1.8em;
     margin-bottom: 5px;
}
 #sidebar.active ul ul a {
     /*padding: 10px !important;*/
}
 #sidebar.active a[aria-expanded="false"]::before, #sidebar.active a[aria-expanded="true"]::before {
     top: auto;
     bottom: 5px;
     right: 50%;
     -webkit-transform: translateX(50%);
     -ms-transform: translateX(50%);
     transform: translateX(50%);
}
 #sidebar .sidebar-header {
     padding: 24px 0 10px;
     background: #23292D;
}
 #sidebar .sidebar-header img.desk-logo {
     width: 150px;
     margin: 0px 15px;
}
 #sidebar .sidebar-header img.mob-logo {
     display: none;
     font-size: 1.8em;
}
/*  #sidebar ul.components {
     padding: 20px 0;
} */
 #sidebar ul.components #dashboard, #sidebar ul.components #poi{
     fill:#dadada;
}
 #sidebar ul li a {
     padding: 8px 0 8px 15px;
     display: block;
     font-family: 'Lato', sans-serif;
     font-weight:400;
     font-size: 16px;
     border-bottom: 1px solid #23292D;
}
 #sidebar ul li:last-child a {
     border: none;
}
 #homeSubmenu{
     background:#ffffff;
}
 #sidebar ul.components li{
     position:relative;
}
 #sidebar ul.components li a span:first-child, .dashbord-type-dropdown span.icon-map{
    position:relative;
    top:2px;
    font-size:16px;
    }
 #sidebar.active #homeSubmenu {
     background: #ffffff;
}
 #homeSubmenu li a{
     color:#23292D !important;
    /*padding-left:115px !important;
    */
     border-bottom: none !important;
}
 #homeSubmenu li a:hover{
     color:#bf2349 !important;
     background:#ffffff !important;
}
 #sidebar ul li a i {
     margin-right: 10px;
}
 #sidebar ul li.active > a/*, a[aria-expanded="true"] */{
     color: #fff;
     background: #bf2349;
    /* display: flex;
     align-items:center;
    */
}
 a[data-toggle="collapse"] {
     position: relative;
}
/* a[aria-expanded="true"]::after {
     content: '\f101';
     display: inline-block;
     font: normal normal normal 14px/1 FontAwesome;
     font-size: inherit;
     text-rendering: auto;
     -webkit-font-smoothing: antialiased;
     position: absolute;
     right: 10px;
     font-size: 20px;
     top:35%;
}
*/
a[aria-expanded="true"] .collapse in::after {content: '\f103'; display: inline-block; font: normal normal normal 14px/1 FontAwesome; font-size: inherit; text-rendering: auto; -webkit-font-smoothing: antialiased; position: absolute; right: 10px; font-size: 20px; top:25%; }
a.download {background: #fff; color: #7386D5; }
a.article, a.article:hover {background: #6d7fcc !important; color: #fff !important; }
.list-unstyled {list-style: none;}
.list-unstyled li img{width:30px; }
.mobile-menu-wrapper .components li a span:nth-child(2) {padding-left: 5px; }
.mobile-menu-wrapper .components{list-style: none; padding:0; margin:0; }
.dashbord-type-dropdown {font-size: 18px; font-family: 'Lato', sans-serif; font-weight:400; color: #d10f41; cursor: pointer; letter-spacing: 1px; padding-top:3px; margin-left:24px; }
.dashbord-type-dropdown .icon-dashboard{position:relative; top:1px; }
.dropdown .dashbord-type-dropdown .icon-colapse_down{font-size:10px !important; padding-left:20px; }
.dashbord-type-dropdown:hover {color: #000000; }
.dashbord-type-dropdown .caret {border-top: 8px dashed; border-right: 7px solid transparent; border-left: 7px solid transparent; cursor: pointer; }
.dashboard-type-wrap .dropdown-menu{margin-left:0 !important; width:300px; position: absolute; margin-top: 19px; border: none; border-radius: 0; -webkit-box-shadow: -2px 4px 2px 0px rgba(0,0,0,0.65); -moz-box-shadow: -2px 4px 2px 0px rgba(0,0,0,0.65); box-shadow: -2px 4px 2px 0px rgba(0,0,0,0.65); padding-bottom:20px; }
.dashboard-type-wrap .dropdown-menu li a span {text-align:right; flex:33%; }
.dashboard-type-wrap .dropdown-menu li a {line-height: 30px; display: flex; padding: 0; margin: 0 20px; }
.dashboard-type-wrap .dropdown-menu li:not(:first-child) a {border-bottom: 1px solid; }
.dashboard-type-wrap .dropdown-menu li:hover{background-color:#f5f5f5; }
.dashboard-type-wrap .dropdown-menu li a:hover{background:none; }
.profile-drop-wrap .dropdown-menu{background: #ffffff; border: none; border-radius: 0; margin-top: 14px; -webkit-box-shadow: -2px 4px 2px 0px rgba(0,0,0,0.65); -moz-box-shadow: -2px 4px 2px 0px rgba(0,0,0,0.65); box-shadow: -2px 4px 2px 0px rgba(0,0,0,0.65); }
.profile-drop-wrap .dropdown-menu li a {line-height: 25px; }
.profile-drop-down span.icon-user_blackwhite{position:relative; top:4px; font-size:18px; }
.profile-drop-down {font-size: 14px; font-family: 'Lato', sans-serif; font-weight:300; color: #565454; cursor: pointer; letter-spacing: 1px; padding-left: 53px; vertical-align: text-top; margin-right:10px; padding-top:3px; }
 .profile-drop-down span{margin-right:10px; font-size:16px; line-height:20px; } 
 .dropdown .profile-drop-down span.icon-colapse_down{font-size:10px; margin-left:20px; } 
 .dropdown.open .profile-drop-down span.icon-colapse_down:before{/*content:'\006f';*/ } 
 .profile-drop-down:hover {color: #d10f41; }
 .profile-drop-down .caret {border-top: 6px dashed; border-right: 6px solid transparent; border-left: 6px solid transparent; cursor: pointer; }
 .profile-drop-down img {padding-right: 10px; }

/* MENU NOTIFICATION BOX */
.dropdown-menu.notification-dropbox{width:340px; margin:0; padding:0; margin-top:15px !important; border:none; -webkit-box-shadow: -6px 4px 1px 0px rgba(142,142,141,1); -moz-box-shadow: -6px 4px 1px 0px rgba(142,142,141,1); box-shadow: -6px 4px 1px 0px rgba(142,142,141,1); border-right:1px solid #8e8e8d; border-bottom:1px solid #8e8e8d;; }
.main-notifications li a {padding: 2px 7px 0; background: transparent !important; display: block; font-size:16px; }
.main-notifications li a{-webkit-transition-duration: 0.3s; transition-duration: 0.3s;}
.main-notifications li a:hover {-webkit-transform: translateY(-2px); transform: translateY(-2px); }
.main-notifications li > a span:first-child{position:relative; top:2px; margin:5px; }
.main-notifications > li:first-child > a span:first-child {color:#bf2349; }
.main-notifications li:first-child a .icon-alert:hover,.main-notifications li:first-child a .icon-alert:active {color:rgba(205,4,56,0.7); }
.main-notifications li:nth-child(2) a span:first-child{color:#f4ab2d; }
.main-notifications li:nth-child(2) a:hover,.main-notifications li:nth-child(2) a:active {color:rgba(244,171,45,0.8); }
a img#testing{fill:orange; }
.notification-dropbox-title{background:#d9d9d9; padding:10px; }
.notification-title {display: flex; align-items: center; }
.notification-title span {margin-right: 5px; font-size: 18px; line-height: 18px; color:#d10f41; }
.notification-header-right span{cursor:pointer; color:#808081; margin-right:10px; }
.notification-title strong {font-size:20px; color:#1d1d1b; padding-left:5px; }
.notification-title #alert2{fill:#bf2349; }
.notification-dropbox-title button{background:#d10f41; border:none; margin-right:15px; }
.notification-dropbox-title button:hover,.notification-dropbox-title button:active,.notification-dropbox-title button:focus,
.notification-dropbox-title button:active:focus, .notification-dropbox-title button:active:hover{background:rgba(209,15,65,0.8); border:none; }
#notification-search-input input{border-right:none; }
#notification-search-input input::-webkit-input-placeholder, .notification-search #datetimepicker1 input::-webkit-input-placeholder { /* Chrome/Opera/Safari */ color: #404140; }
#notification-search-input input::-moz-placeholder, .notification-search #datetimepicker1 input::-moz-placeholder { /* Firefox 19+ */ color: #404140; }
#notification-search-input input:-ms-input-placeholder, .notification-search #datetimepicker1 input:-ms-input-placeholder { /* IE 10+ */ color: #404140; }
#notification-search-input input:-moz-placeholder, .notification-search #datetimepicker1 input:-moz-placeholder { /* Firefox 18- */ color: #404140; }
#notification-search-input input.form-control:focus{border: 1px solid #ccc; box-shadow: none; border-right: none; }
#notification-search-input button{background:#fff; border:1px solid #ccc; border-left:none; padding:2px; }
#notification-search-input button img{width:25px; }
.notification-search #datetimepicker1 input{border-radius:4px;}
.notification-search #datetimepicker1 .input-group-addon{background:#ffffff; }
.check-container {display: block; position: relative; padding-left: 22px; margin-bottom: 12px; cursor: pointer; -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; }
.check-container input {position: absolute; opacity: 0; cursor: pointer; }

/* Create a custom checkbox */
.checkmark {position: absolute; top: 3px; left: 0; height: 15px; width: 15px; /*background-color: #ffffff;*/ border:1px solid #868686; }
.check-container:hover input ~ .checkmark {background-color: #ccc; }
.check-container input:checked ~ .checkmark {background-color: #ffffff; border:1px solid #868686; }
.checkmark:after {content: ""; position: absolute; display: none; }
.check-container input:checked ~ .checkmark:after {display: block; }
.check-container .checkmark:after {left: 4px; top: 0px; width: 5px; height: 10px; border: solid #868686; border-width: 0 1px 1px 0; -webkit-transform: rotate(45deg); -ms-transform: rotate(45deg); transform: rotate(45deg); }
.notification-dropbox .drop-content{padding: 10px; background: #f3f3f3; height: calc(100vh - 100px); overflow:auto; }
.notification-filters{margin: 10px 0; overflow: hidden; }
.notification-filters ul,.notification-intro ul{list-style:none; display: block; overflow: hidden; padding: 0; margin: 0; }
.notification-filters ul li{display: inline-block; width: 30%; margin: 5px 2px; }
.drop-content-inner .panel-title, .drop-content-inner .map-address{font-size:12px; }
.drop-content-inner .panel-heading{padding:5px 0px !important; }
.drop-content-inner .map-address{margin-top:10px; }
.drop-content-inner .notification-intro-head{line-height: 20px; margin-bottom: 5px; float: left; }
.drop-content-inner .notification-intro-head.left{font-weight:900; }
.drop-content-inner .panel-group .panel{border-radius:0; border:none; }
.notification-intro-head img, .notification-intro-head span:first-child{float:left; }
.notification-intro > ul li{float:left; margin:2px; }
.notification-intro > ul li:first-child{margin-left:0; }
.notification-intro > ul> li:first-child{width:20px; }
.notification-intro > ul> li:nth-child(2) {width: 55%; }
.notification-intro > ul> li:nth-child(2) span:first-child {font-weight: 900; }
.notification-intro > ul> li ul{padding:0; margin:0; }
.notification-intro > ul> li ul li{font-style:italic; }
.notification-map img{width:100%; }
.drop-content-inner .panel-body{padding:0px 5px 14px; }
.notification-border{position: relative; text-align:center; color:#d9dadb; }
.notification-border:after {content: ''; position: absolute; height: 2px; background: #d9dadb; right: 5px; width: 47%; top: 4px; }
.notification-border:before {content: ''; position: absolute; height: 2px; background: #d9dadb; left: 5px; width: 47%; top: 4px; }
.collapse.in + .notification-border span{transform:rotate(180deg); }
.collapse.in + .notification-border:before,.collapse.in + .notification-border:after {top:14px; }
 

/* DASHBOARD */

.dashboard-filters .container-fluid{padding:0;}
.dashboard-top-default button{font-size:18px;color:#2c3031;background:transparent;height:33px;padding:2px 12px;}
.dashboard-filters-left{padding-left:6px;}
.dashboard-filters-right{margin-top:7px;}
.dashboard-top-default button:active, .dashboard-top-default button:focus,.dashboard-top-default button:active:focus{box-shadow:none;
outline:0;}
.dashboard-top-default span{font-size:10px; margin-top:5px; margin-left:20px; }
.dashboard-top-default ul, .dashboard-top-day ul{border:0; border-radius:0; width:100%;}
.dashboard-top-search {padding:0 0 0 10px; border-radius: 0; background-color: #fff; }
.dashboard-top-search input {border: 0; box-shadow: none;z-index:0 !important;padding-left:2px;  }
.dashboard-top-search input:focus, .dashboard-top-search input:active:focus{border:none;box-shadow:none;}
.dashboard-top-search button {margin: 0; background: none; box-shadow: none; border: 0; color: #d10f41; padding: 0;z-index:0 !important;}
.dashboard-top-search button img{width:25px; margin-top:3px;z-index:0 !important; }
.dashboard-top-search button:hover,.dashboard-top-search button:focus,.dashboard-top-search button:active,.dashboard-top-search button:active:focus {border: 0; box-shadow: none; background:transparent;outline:none; }
/*.dashboard-top-day{background:#ffffff;} */
.dashboard-top-day span.caret{border-top: 5px dashed; border-top: 5px solid\9; border-right: 5px solid transparent; border-left: 5px solid transparent;position:absolute;right:20px;top:15px; }
.dashboard-top-day button{background:transparent;width:100%;text-align:left; }
.dashboard-top-day button:active,.dashboard-top-day button:focus,.dashboard-top-day button:active:focus{outline:none;box-shadow:none;}
.dashboard-top-day span{margin-left:70px !important; }
.dashboard-top-date .form-control{border-radius:0;border:none;box-shadow:none; }
.dashboard-top-date{width:100%;}
.dashboard-top-date  span.filter-date,.notification-search span.end-date{position:relative;width:100%;z-index:0;}

.dashboard-top-date  span.filter-date:after, .notification-search span.end-date:after{position: absolute; background: url(https://staticcontent.inelcan.com/img/date.svg); content: ''; right: 5px; width: 27px; height: 25px; top: 3px; z-index: 998; pointer-events: none; }
.dashboard-top-date input{border-right:0;}
.dashboard-top-date .input-group-addon{border-radius:0;background:transparent;border-radius: 0;background: #fff;border: none;cursor:pointer;}

.dashboard-menu-right li{padding:0 !important; }
.dashboard-menu-right ul li{margin:0 1px; }
.dashboard-menu-right li a{border:2px solid #2c3031; color:#2c3031; padding:5px 7px; border-radius:3px;    -webkit-transition-duration: 0.2s;
    transition-duration: 0.2s; }
.dashboard-menu-right li a:hover{box-shadow: 0 0 5px rgba(0, 0, 0, 0.6);border:2px solid #fff;color:#ffffff;background:#bf2349;}
.dashboard-menu-right li a span {position: relative; top: 2px; }
.dashboard-menu-right li:nth-child(3) a,.dashboard-menu-right li:nth-child(5) a{margin-right:30px; }

.dashboard-content{margin-top:40px;}

.dashboard-content .dashboard-box{background: #ffffff; margin: 5px 0; padding: 0;overflow:hidden;}
.dashboard-content .dashboard-box h3{font-size:16px; font-weight:900;margin-top:10px; }
.dashboard-content .dashboard-box .panel{border: none; box-shadow: none; margin-bottom: 0;}
.dashboard-content .dashboard-box .panel .panel-heading{background:none;padding:0;border:none;padding-top:5px;}
.dashboard-content .dashboard-box .panel .panel-body{padding:5px;border:none;}
.dashboard-content .dashboard-box .panel .panel-heading .home-dash-heading { padding:5px 10px 5px 10px}
.dashboard-content .dashboard-state-wrap .dashboard-box .panel .panel-heading .home-dash-heading,
.dashboard-content .dashboard-global-wrap .dashboard-box .panel .panel-heading .home-dash-heading { padding:5px 0px 5px 5px}
.dashboard-content .dashboard-box .panel .panel-heading .home-dash-heading a{float:right;}

.dashboard-content .dashboard-box .panel .panel-heading .home-dash-heading span img{width:50px;}
.dashboard-content .dashboard-box .panel .panel-heading .home-dash-heading span:first-child{font-size:15px; position: relative; top:2px; color:#bf2349;float:left;/*width:9%;*/}

.dashboard-content .dashboard-box .panel .panel-heading .home-dash-heading a span{color:#919292 !important;}
.dashboard-content .dashboard-box .panel .panel-heading .home-dash-heading{font-size: 13px; font-family: 'Lato', sans-serif; font-weight:900;  letter-spacing: 1px; vertical-align: middle;}
.dashboard-content .dashboard-box .panel .panel-heading .home-dash-heading .home-dash-heading-right{display:inline-block;float:right;position: absolute; right: 5px; top: 10px;}
.dashboard-content .dashboard-box .panel .panel-heading .home-dash-heading .home-dash-heading-right> span{margin-right:5px;}
.dashboard-content .dashboard-box .panel .panel-heading .home-dash-heading .home-dash-text{text-transform: uppercase;font-weight:900;font-size:15px;margin-left:5px;}
.home-dash ul li.active > a, a[aria-expanded="true"] {background-color: transparent; } 
.dashboard-box .dashboard-box-body {padding: 0px 5px 0 8px; }
.dashboard-box .dashboard-box-body .dashboard-box-body-arrow{color: #d10f41; font-size: 24px; vertical-align: text-top; padding-left: 10px;}
/*.dashboard-box .dashboard-box-body .dashboard-box-body-left{width:80%;}*/
.dashboard-box .dashboard-box-body .dashboard-box-body-left, .dashboard-driving-wrap .dashboard-box-body .dashboard-box-body-right{float:left;}
.dashboard-content .dashboard-box .Numbers {font-size: 25px; font-family: 'Lato', sans-serif; font-weight:700; }
.dashboard-content .dashboard-box .Letters {font-size: 20px; font-family: 'Lato', sans-serif; font-weight:300; padding: 0 10px 0 0; }
.dashboard-content .calculation,.global-fleet {display: block; padding: 10px;height:40px; }
.dashboard-content .calculation .letters,.dashboard-content .global-fleet{font-family: 'Lato', sans-serif; font-weight:300; font-size: 14px; color:#848588; font-weight: 600; }
.dashboard-content .calculation .numbers {font-family: 'Lato', sans-serif; font-weight:400; color: #848588; font-size: 16px; font-weight: 600; }
.dashboard-content .calculation .yellow{font-family: 'Lato', sans-serif; font-weight:300; color: #f47a2c; font-weight: 900; font-size: 16px; }
.dashboard-stops-wrap .calculation .dashboard-box-body-arrow{color: #4ebc79; font-size: 26px; vertical-align: top; padding: 0; display: inline; /*margin-left: 10px;*/}

.dashboard-notifications-wrap .notification-body{padding:5px; }
.dashboard-notifications-wrap .notification-body .driver-name,.dash-notification-body .dash-notification-body-list{margin-left:25px; }
.dashboard-notifications-wrap .notification-body .dash-notification-body-list li{margin-left:0; }
.dashboard-notifications-wrap .notification-content{margin-left:30px;font-size:16px; }
.dashboard-notifications-wrap .notification-content span, .dashboard-notifications-wrap .notification-content ul li{font-weight:bold;color:#23292D;}
.dashboard-notifications-wrap .notification-body .notification-head-left span:first-child{margin-right:10px;}
.dashboard-notifications-wrap .panel-body .notification-body:not(:last-child){border-bottom:1px solid #d0d3d3; padding-bottom:10px;padding-top:10px; }
.dashboard-notifications-wrap .dashboard-box,.dashboard-reports-wrap .dashboard-box{padding:5px;}

.dashboard-notifications-wrap .notification-content ul {margin-top: 3px; }
.dashboard-notifications-wrap .notification-content ul li{float: left; margin-right:30px; font-style:italic; }
.notification-content{margin-top:5px; margin-bottom:5px; }
.dashboard-notifications-wrap .notification-head-right span{margin-left:8px;font-weight:normal;}
.dashboard-notifications-wrap .notification-head-right, .dashboard-notifications-wrap .notification-head-right span{color:#23292D !important;font-size:14px;font-weight:700;}
.notification-head span{font-weight:900; color:#23292D; font-family: 'Lato', sans-serif; }
.dashboard-notifications-wrap .notification-border {position: relative; text-align: center; color: #d9dadb; }
.dashboard-notifications-wrap .notification-border span{transform:rotate(180deg);}
.dashboard-notifications-wrap .notification-border:before {content: ''; position: absolute; height: 2px; background: #d9dadb; left: 1%; width: 48%; top: 14px; }
.dashboard-notifications-wrap .notification-border:after {content: ''; position: absolute; height: 2px; background: #d9dadb; right: 1%; width: 48%; top: 14px; }



.dashboard-global-wrap .dashboard-box, .dashboard-state-wrap .dashboard-box{padding:5px;}
.dashboard-global-body{margin-top:-33px;}
.dashboard-global-body table{width:90%;}
.dashboard-state-body table{width:100%;}
.dashboard-global-wrap .panel-body table tr td, .dashboard-state-wrap .panel-body table tr td {height: 35px; text-align:center;color:#23292D !important; }
.dashboard-global-wrap .panel-body table tr td .red-font{color:#ee1c25;}
.dashboard-global-wrap .panel-body table tr td .green-font{color:#8cc63f;}
.dashboard-global-wrap .panel-body table tr td:nth-child(1){text-align:left;width:40%;}
/*, .dashboard-state-wrap .panel-body table > tr td:nth-child(1){width:50%;}*/
.dashboard-state-wrap .panel-body table tr td:nth-child(1){text-align:left;width:35%;}
.dashboard-global-wrap .panel-body table tr td:nth-child(2){border-left:1px solid #000000; border-right:1px solid #000000; }
.dashboard-global-wrap .panel-body table tr.global-body-heading td:nth-child(2){border:none !important;}
.dashboard-global-wrap .panel-body table tr td:nth-child(4){border-left:1px solid #000000; }
.dashboard-global-wrap .panel-body table tr.global-body-heading td:nth-child(4){border:none !important;}
.dashboard-global-wrap .panel-body table tr td, .dashboard-state-wrap .panel-body table tr td{padding-bottom:0px; }
.dashboard-global-wrap .panel-body table tr td{padding-bottom:14px;}
.dashboard-state-wrap .panel-body table tr td table tr{border-bottom:10px solid #ffffff;}

.dashboard-state-wrap .panel-body table tr:nth-child(1) td table tr td:nth-child(1) span:first-child{background:#38b449;height:100%;}
.dashboard-state-wrap .panel-body table tr:nth-child(1) td table tr td:nth-child(1) span:nth-child(2){background:#d9edd4;height:100%;}
.dashboard-state-wrap .panel-body table tr:nth-child(1) td table tr td:nth-child(2) span:first-child{background:#d9edd4;height:100%;}
.dashboard-state-wrap .panel-body table tr:nth-child(1) td table tr td:nth-child(3) span:first-child{background:#d9edd4;height:100%;}
.dashboard-state-wrap .panel-body table tr:nth-child(1) td table tr td:nth-child(3) span:nth-child(2){background:#e1e3e1;height:100%;}

.dashboard-state-wrap .panel-body table tr:nth-child(2) td table tr td:nth-child(1) span:first-child{background:#f2ed4f;height:100%;}
.dashboard-state-wrap .panel-body table tr:nth-child(2) td table tr td:nth-child(1) span:nth-child(2){background:#fcfbdd;height:100%;}

.dashboard-state-wrap .panel-body table tr:nth-child(3) td table tr td:nth-child(1) span:first-child{background:#ee2e36;height:100%;}
.dashboard-state-wrap .panel-body table tr:nth-child(3) td table tr td:nth-child(1) span:nth-child(2){background:#fcd5c6;height:100%;}

.dashboard-state-wrap .panel-body table tr:nth-child(4) td table tr td:nth-child(1) span:first-child{background:#9c2280;height:100%;}
.dashboard-state-wrap .panel-body table tr:nth-child(4) td table tr td:nth-child(1) span:nth-child(2){background:#e2ccdf;height:100%;}

.dashboard-state-wrap .panel-body table tr:nth-child(5) td table tr td:nth-child(1) span:first-child{background:#2e51a3;height:100%;}
.dashboard-state-wrap .panel-body table tr:nth-child(5) td table tr td:nth-child(1) span:nth-child(2){background:#cccee7;height:100%;}

.dashboard-state-wrap .panel-body table tr:nth-child(6) td table tr td:nth-child(1) span:first-child{background:#000002;height:100%;}



.dashboard-state-wrap .panel-body table tr td table tr td{background:#e1e3e1;border:1px solid #ffffff;width:22%;padding:0;}
.dashboard-state-wrap .panel-body table tr td table tr td:last-child{text-align:right;padding-right:5px;font-size:14px;}
.dashboard-state-wrap .panel-body table tr td table tr td:nth-child(1){width:28%;}
.progress-bar-home-dash span,.dashboard-global-wrap table td,.dashboard-state-wrap table td {font-family: 'Lato', sans-serif; font-weight:300; font-size: 18px; color: #565454; font-weight: 700; letter-spacing: 1px; padding-left: 5px; }
/*#home {background-color: #d0d3d3; width: 100%; } */
/*.menu-dashboard .list-inline {margin-bottom: 34px; float: left;border:3px solid blue; } */
/*.menu-dashboard .list-inline {margin-bottom: 34px; display: flex; } */
/*.home-dash .panel-title {float: right; background:red;} */
/*.home-dash .panel-title a {padding-right: 9px; } */
/*.home-dash .box-heading img {width: 50px; } */
/*.panel-title > a:before {font-family: FontAwesome; content: "\f068"; padding-right: 5px; color: #565454;} 
.panel-title > a.collapsed:before {content: "\f067"; color: #565454; } 
.panel-title > a:active, .panel-title > a:focus, .panel-title > a:hover {text-decoration: none; } */
.home-dash .panel-default {background-color: #fff; border-radius: 4px; border: none; overflow: hidden; display: block; -webkit-box-shadow: 14px 13px 7px -12px #b8b8b8; -moz-box-shadow: 14px 13px 7px -12px #b8b8b8; box-shadow: 14px 13px 7px -12px #b8b8b8; }
.home-dash-header {padding: 25px;} 
.home-dash-drop {font-size: 18px; font-family: 'Lato', sans-serif; font-weight:400; color: #565454; cursor: pointer; font-weight: 600; letter-spacing: 1px; padding-left: 5px; vertical-align: middle; } 
.home-dash-drop:hover {color: #d10f41; } 
.home-dash-drop .caret {border-top: 5px dashed; border-right: 5px solid transparent; border-left: 5px solid transparent; cursor: pointer; }
.home-dash-numbers {float: right; font-family: 'Lato', sans-serif; font-weight:400; font-size: 18px; } 
.color-home-dash {color: #d10f41 !important; font-weight: 900 !important; } 
.heading-notification img{width:30px; } 
.home-dash-body {padding: 0px 20px 0; } 
.home-dash-heading .home-dash-text, .home-dash-heading .home-dash-text span{color:#23292D !important; } 

.heading-notification {display: block; overflow: hidden; position: relative; } 
.notification-body,.reports-body {padding: 5px 33px 0 15px; font-size: 16px; width: 95%; }
 #sidebarCollapse1 {display: none; }
.heading-notification .pull-left {font-family: 'Lato', sans-serif; font-weight:400; font-size: 18px; color: #565454; font-weight: 600; padding: 0 13px 0 0; } 
.heading-notification .pull-right {font-family: 'Lato', sans-serif; font-weight:300; font-size: 18px; color: #565454; }
.notification-body p {font-family: 'Lato', sans-serif; font-weight:300; font-size: 18px; color: #565454; padding: 23px 33px 0; }


.home-dash-body table{width:100%; }
.progress-bar-home-dash td {height: 50px; }
.box-heading {border-bottom: 1px solid #d0d3d3; padding: 6px 0px 5px 0px; }
#collapseFive .pull-right i {color: #0fc05a; font-size: 35px; vertical-align: text-top; padding-left: 10px; margin-top: -10px; }
.reports-body .reports-data{width:80%; margin-bottom:20px; }
.home-dash-heading .icon-menu_circle{color:#000000; }
.reports-body .report-details {list-style:none; padding:0; margin:0; margin-bottom:20px;margin-top:20px; }
.reports-body .report-details li{border-bottom:1px solid #23292D; font-family: 'Lato', sans-serif;padding:2px 0px; }
.reports-body .report-details li label,.reports-body .report-details li .content{float:left; color:#23292D; padding-left:10px; }
.reports-body .report-details li label{width:30%; font-weight:700; }
.reports-body .report-details li .content{width:70%; font-weight:700; }
.reports-body .report-details li .content span{margin-left:5px;}
.home-dash-heading .icon-document{position:relative; top:3px; color:#bf2349; }


/*Edit dashboard*/
.edit-dashboard-wrapper{padding:65px 40px 50px 200px; transition:all 0.4s; }
.sidebar-collapse .edit-dashboard-wrapper {padding: 65px 40px 50px 52px; }
.edit-dashboard-wrapper .widgets-wrap{padding-top:40px;}
.edit-dashboard-left{background:#ffffff; padding:10px 20px; min-height:100%;/*height:100vh;*/ overflow:auto; width:300px; float: left;padding-bottom:100px; }
.edit-dashboard-right{float: left;margin-left:15px;width: calc(100% - 315px); }
.edit-dashboard-left h4{color:#bf2349; }
.edit-dashboard-left span.edit-category, .edit-dashboard-left span.sub-category{position:relative;width:100%;}
.edit-dashboard-left span.edit-category select, .edit-dashboard-left span.sub-category select{-moz-appearance: none;-webkit-appearance: none;cursor:pointer;}
/* .edit-dashboard-left span.edit-category:after, .edit-dashboard-left span.sub-category:after{content: ''; position: absolute; width: 25px; height: 12px; background: url(../images-new/down-arrow.png); background-repeat: no-repeat; top: 13px; right: 5px; pointer-events: none; background-size: 50%; background-repeat: no-repeat; } */
.edit-dashboard-left .edit-form-search{margin-top:40px; }
.edit-dashboard-left .widgets-list {margin-top:35px; }
.edit-dashboard-left .widgets-list ul{list-style:none; margin:0; padding:0; }
.edit-dashboard-left .widgets-list ul li{border: 2px solid #bdbec1; padding: 15px 0 0; display: inline-block; width: calc(50% - 13px); margin: 6px 0; text-align: center; border-radius: 3px; }
.edit-dashboard-left .widgets-list ul li:nth-child(odd){margin-right:7px; }
.edit-dashboard-left .widgets-list ul li:nth-child(even){margin-left:16px; }
.edit-dashboard-left .widgets-list h4{margin-top:10px; color:#23292D; font-size:16px; text-align:center;font-weight:700; }
.edit-dashboard-left .widgets-list h4 a:hover{color:#bf2349;}
.edit-dashboard-left .widgets-list span{color:#bf2349; font-size:45px; height:45px; }
.edit-dashboard-left .edit-form-search input, .edit-form-search select{border-radius:0; }
.edit-dashboard-left .edit-form-search label{color:#94979e; font-size:12px; }
/*.edit-dashboard #sidebar,.edit-dashboard nav.navbar{display:block; }*/
/*.edit-dashboard .sidebar-header.mobile-view{display: none; }*/
/*.edit-dashboard-wrapper{margin-top:1px; }*/

.edit-dashboard-right .dashboard-box .panel .panel-heading .home-dash-heading span:first-child{font-size:15px; position: relative; top:2px; color:#bf2349;}
.edit-dashboard-right .dashboard-box .panel .panel-heading .home-dash-heading a span{color:#919292 !important;}
.edit-dashboard-right .dashboard-box .panel .panel-heading .home-dash-heading{font-size: 13px; font-family: 'Lato', sans-serif; font-weight:900;  letter-spacing: 1px; vertical-align: middle;}
.edit-dashboard-right .dashboard-box .panel .panel-heading .home-dash-heading .home-dash-heading-right{display:inline-block;float:right;}
.edit-dashboard-right .dashboard-box .panel .panel-heading .home-dash-heading .home-dash-heading-right a{box-shadow:none;}
.edit-dashboard-right .dashboard-box .panel .panel-heading .home-dash-heading .home-dash-heading-right a span:hover{color:rgba(0, 0, 0, 0.6);}
.edit-dashboard-right .dashboard-box .panel .panel-heading .home-dash-heading .home-dash-heading-right> span{margin-right:5px;}
.edit-dashboard-right .dashboard-box .panel .panel-heading .home-dash-heading .home-dash-heading-right> span:first-child{font-size:16px;}
.edit-dashboard-right .dashboard-box .panel .panel-heading .home-dash-heading .home-dash-text{text-transform: uppercase;font-weight:900;font-size:15px;margin-left:5px;}

.edit-dashboard-right .widget-buttons{margin-top:40px; }
.edit-dashboard-right .widget-buttons button:first-child{background:transparent; }
.edit-dashboard-right .widget-buttons button:last-child{color:#ffffff; background:#bf2349; }
.edit-dashboard-right .widget-box{border-radius:10px; }
.edit-dashboard-right .widget-wrap .panel .panel-heading{background:#e1e3e1;}
.edit-dashboard-right .widget-wrap .dashboard-box .panel-heading{background:none;}
.edit-dashboard-right .widget-box .panel-heading{height:40px;}

.edit-dashboard-right .outer-panel-heading > a span{font-size: 27px; color: #000000; display: inline-block; margin-top: -5px;-webkit-transition-duration: 0.1s; transition-duration: 0.1s;}
.edit-dashboard-right .outer-panel-heading > a span:hover{color:rgba(0, 0, 0, 0.6);font-size: 28px;}
.edit-dashboard-right .left-inner-widget-head{float: left;}
.edit-dashboard-right #collapse-heading-outer .panel-heading{margin-bottom:5px;padding:5px;overflow: hidden;}
.edit-dashboard-right #collapse-heading-outer .dashboard-state-wrap .panel-body table tr td{vertical-align:baseline;}
.edit-dashboard-right #collapse-heading-outer .dashboard-state-wrap .dashboard-box{padding:0;}
.edit-dashboard-right .add-widget-box .panel-body{padding:5px 5px 0 5px;border:none !important; } 
.edit-dashboard-right .widget-box, .edit-dashboard-right .widget-box a,.edit-dashboard-top li a, .edit-dashboard-left .widgets-list ul li{-webkit-transition-duration: 0.2s; transition-duration: 0.2s;}
.edit-dashboard-right .widget-box:hover {box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.6); border-radius: 4px; }
.edit-dashboard-right .widget-box a:hover, .edit-dashboard-top li a:hover{box-shadow: 0 0 4px rgba(0, 0, 0, 0.6);color:#ffffff;background:#bf2349;}
.edit-dashboard-left .widgets-list ul li:hover{box-shadow: 0 0 4px rgba(0, 0, 0, 0.6);}

.edit-dashboard-right .add-widget-box{width:100%;}
.edit-dashboard-right .empty-widget-wrap .panel-body{min-height:337px;border: 1px solid #e4e8e7 !important;padding: 0; position: relative; background: transparent; color: #a5abac; }
.edit-dashboard-right .empty-widget-wrap .empty-widget{position:relative;}
.edit-dashboard-right .empty-widget-wrap .panel{background:transparent;}
.edit-dashboard-right .empty-widget-wrap .empty-widget-content{ position: absolute; top: 50%; left: 50%; -webkit-transform: translate(-50%,-50%); -moz-transform:translate(-50%,-50%); -ms-transform:translate(-50%,-50%); -o-transform:translate(-50%,-50%); transform:translate(-50%,-50%); }
.edit-dashboard-right .empty-widget-wrap .empty-widget-content span{display:block;}
.edit-dashboard-right .empty-widget-wrap .empty-widget-content a span:first-child{font-size:20px;color:#818284;margin-bottom:8px;}
.edit-dashboard-right .empty-widget-wrap .empty-widget-content a span.add-widget{color:#23292D !important;font-size:15px;font-weight:700;}

.widgets-area .widget-links{display: block; overflow:hidden; }
.widget-content{margin-top:120px; }
.widget-content p{color:#a5abac; } 
.widgets-area .widget-links ul{list-style:none; float: right; display: block; margin-bottom:20px; } 
.widgets-area .widget-links ul li{display:inline; margin:0px 10px; } 
.widget-edit-links{display:inline; margin:0; padding:0; } 
.widget-edit-links+span{float:right; } 
.widget-edit-links li {display:inline; } 
.widget-edit-links li a.edit-link{border: 2px solid #2c3031; color: #2c3031; padding: 3px 5px; border-radius: 3px; } 
.empty-widget .panel{background:none; } 
.empty-widget .panel-heading{background:#e1e3e1; padding:10px; height:40px; } 
.empty-widget .add-widget-box{min-height: 250px; padding:50px; text-align: center; height:200px; } 
.empty-widget .panel-body span,.empty-widget .panel-body h4{display:block; width:100%; } 
.add-widget-box{text-align:center; } 
.add-widget-box .empty-widget span{font-size:25px; font-weight:700; } 
.add-widget-box .home-dash-body{padding:0; } 
.edit-dashboard-state-wrap .panel-body{padding:5px; } 
.edit-dashboard-state-wrap .progress-bar-home-dash span{font-size:16px; text-align:left; } 
.edit-dashboard-state-wrap .panel-heading{padding-top:0; } 
.add-widget-box .panel{border:0;box-shadow:none; } 
.add-widget-box .panel-heading{background:none;border:none; } 
#headingInternal .home-dash-heading{padding:0;height:20px; } 
#headingInternal .home-dash-heading .icon-map{float: left; } 
.edit-dashboard-top li:first-child a span{font-size:10px;} 
.edit-dashboard-top li a{border: 2px solid #2c3031; color: #2c3031; padding: 5px 7px; border-radius: 4px;font-weight:700; } 
.edit-dashboard-top li a span{max-height: 22px; margin: 3px 10px 0 3px; height: 20px; vertical-align: middle;}
.wrapper {min-height: 100%; position: relative; }

/*Map left menu*/
.map-left-menu{width:70px; background:#f3f3f3; height:100%; position:fixed; z-index:100;padding-top:20px; }
.map-left-menu ul li{width:100%; text-align:center; padding:5px 0;margin:0; }
.map-left-menu ul li a span:first-child{padding: 10px 15px; width: 54px; margin: 0 auto; display: block;}
.map-left-menu ul li a.active span:first-child, .map-left-menu ul li a:hover span:first-child{background:#bf2349;border-radius: 50%;color:#ffffff; }
.map-left-menu ul li span{font-size:23px;/*width:25px;height:25px;*/}
#map-content-wrap{position: relative;z-index:1;}
#map-wrapper{position: fixed; top: 0; left: 0; bottom: 0;}

/*Map right side bar*/
#sidebar-map {width: 400px; background: #fff; -moz-transition: all 0.3s; -webkit-transition: all 0.3s; -o-transition: all 0.3s; -ms-transition: all 0.3s; transition: all 0.3s; } 
.sidebar-collapse-map aside .map-content-collapsible{display:none;}
.map-content-collapsible {width: 100%; position:fixed; padding:15px; top: 0; right: 0; z-index: 1; -moz-transition: all 0.3s; -webkit-transition: all 0.3s; -o-transition: all 0.3s; -ms-transition: all 0.3s; transition: all 0.3s; }
.map-content-collapsible button{background: transparent; top: 30px; padding: 0 20px; float: none;  right: 0;border:none;font-size: 20px;}

.main-sidebar-map {padding:15px; top: 0; right: 0; z-index: 1; -moz-transition: all 0.3s; -webkit-transition: all 0.3s; -o-transition: all 0.3s; -ms-transition: all 0.3s; transition: all 0.3s; }
.main-sidebar-map button{background: transparent; top: 30px; padding: 0 20px; float: none;  right: 0;border:none;font-size: 20px;}

.sidebar-collapse-map .main-menu-toggle i{transform:rotate(180deg);}
.sidebar-collapse-map #sidebar-map {width: 50px; }
.map-main-heading-left span{font-size: 30px; border: 2px solid #bf2349; border-radius: 70px; padding: 12px 15px; float: left; color: #bf2349;}
.map-main-heading-left h3{float:left;margin-top:20px;margin-left:10px;font-weight:900;font-size:20px;}
.map-search-wrap{margin-top:10px;}
.map-search-wrap .map-search-left{width: 80%;float: left;}
.map-search-wrap .map-search-right{width: 20%;float: left;}
.map-search-wrap .map-search-left input{border:none;box-shadow:none;border-radius:0;}
.map-search-wrap .map-search-left button{top:0;background:#ffffff;padding:1px;height:38px;padding-right:10px;border-radius:0;}
.map-search-wrap .map-search-left button img{width:30px;}
.map-filter-links{margin-top:10px;}
.map-filter-links-left, .map-search-wrap .map-filter-links-right{float:left;}
.sidebar-map-content{margin-top:10px;}
.sidebar-map-content .panel-group{margin-bottom:0;}
.map-filter-links-right{margin-left:20px;float: right;padding-right:20px;}
.map-search-wrap .map-search-right a span{text-align: center; padding: 0px 20px;font-size:32px; }
.map-filter-links-right ul li{display: inline;margin:0 8px;}
.map-filter-links-right ul li a span{color:#b1b1b1;font-size:18px;}
.map-filterlinks-right ul li span{font-size:18px;}
.sidebar-map-content .panel-body{padding:5px 0px 0px 0px;}
.location-detail-box .panel-body{padding:0px 0px 0px 0px;}
.sidebar-map-content .panel-body2{padding:0px 0px 0px 0px;}
.sidebar-map-content .panel-heading{padding:6.5px 10px;}
.sidebar-map-content .panel-heading.map-content-level1{background:#23292d;color:#ffffff; border-radius: 5px 5px 0 0px;}
.sidebar-map-content .panel, .sidebar-map-content .panel-heading{border-radius:0;}
.sidebar-map-content .map-content-level2{background:#d9d9d9;position:relative; line-height: 1.2;}
/*.sidebar-map-content [aria-expanded="true"] .panel-heading.map-content-level2{background:#d9d9d9;}
.sidebar-map-content [aria-expanded="false"] .panel-heading.map-content-level2{background:#ffffff;}*/
.sidebar-map-content .panel-heading a.collapsed .level1-heading-right span i:before{content:"\f0d7";}
.sidebar-map-content .level1-heading .level1-heading-left>span{padding-left:10px;}
.sidebar-map-content .level1-heading .level1-heading-left, .sidebar-map-content .level2-heading .level2-heading-left, .sidebar-map-content .level3-heading .level3-heading-left{display:inline;}
.sidebar-map-content .level2-heading .level2-heading-left span.bg-left{width: 6px; height: 34px; position: absolute; left: 0; top: 0;}
.sidebar-map-content .level2-heading .level2-heading-left span.map-green-bg{background: #3ba935;}
.sidebar-map-content .level2-heading .level2-heading-left span.map-yellow-bg{background: #eee411;}
.sidebar-map-content .level2-heading .level2-heading-left span.map-blue-bg{background: #1d70b7;}
.sidebar-map-content .level2-heading .level2-heading-left span.map-red-bg{background: #e20613;}
.sidebar-map-content .level2-heading .level2-heading-left span.map-violet-bg{background: #662482;}
.sidebar-map-content .level2-heading .level2-heading-left span.map-black-bg{background: #000;}

.sidebar-map-content .level1-heading .level1-heading-left{line-height:25px;}
.sidebar-map-content .level1-heading .level1-heading-left span{font-size:18px;}
.sidebar-map-content .level1-heading .level1-heading-left .check-container{margin-bottom:0;}
.sidebar-map-content .level1-heading .level1-heading-right,.sidebar-map-content .level2-heading .level2-heading-right,.sidebar-map-content .level3-heading .level3-heading-right{float:right;line-height:25px;height:25px;}
.group-content .collapsed .level1-heading-right span i{transform:rotate(-90deg);}
.group-content .level1-heading-right span i{transform:rotate(0deg);}
.sidebar-map-content .map-content-level2 .level2-heading span{font-size:17px;font-weight:700;}
.sidebar-map-content .map-content-level2 .level2-heading span:nth-child(2){position:relative;top:2px;margin-left:3px;}
.sidebar-map-content .level2-heading .level2-heading-right ul li{display:inline;}
.sidebar-map-content .level2-heading .level2-heading-right ul li a span{color:#9c9b9b;}

.sidebar-map-content .level2-heading .level2-heading-right ul,.sidebar-map-content .level2-heading .level2-heading-right span{float:left;}
.sidebar-map-content .level2-heading .level2-heading-right ul+span{line-height:0px;}
.sidebar-map-content .level1-heading .level1-heading-right span:first-child{margin-right:30px;}
.sidebar-map-content .check-container input:checked ~ .checkmark{background-color:transparent;border:1px solid #ffffff;}
.sidebar-map-content .check-container .checkmark:after {left: 4px; top: 0px; width: 5px; height: 10px; border: solid #ffffff; border-width: 0 1px 1px 0; -webkit-transform: rotate(45deg); -ms-transform: rotate(45deg); transform: rotate(45deg); }
.sidebar-map-content .level2-heading .level2-heading-left span{margin-right:5px;color:#1d1d1b;}
.sidebar-map-content .list-group-item{border-radius:0;display:inline-block;padding:0;background:none;border:none;}
.sidebar-map-content .list-group-item:focus, .sidebar-map-content .list-group-item:hover{background:transparent;}
.sidebar-map-content .level2-content{padding:5px 0 0 0 !important;}

.sidebar-map-content .level2-content-top{padding:0 10px;}
.sidebar-map-content .level2-content-bottom{margin-top:7px;}
.sidebar-map-content .level2-content-bottom .panel.map-content-level3{border:none;border-bottom:1px solid #b1b1b1;}
.sidebar-map-content .level2-content-bottom .panel.map-content-level3 .panel-body{padding:10px;}
.sidebar-map-content .level2-content-bottom .panel.map-content-level3 table{width:auto;}
.sidebar-map-content .level2-content-bottom .panel.map-content-level3 table tr td{text-align:left;}
.sidebar-map-content .level2-content-bottom .panel.map-content-level3 .panel-heading{padding:2px 10px 4px;}
.sidebar-map-content .level2-content-middle{border-top:1px solid #b1b1b1;border-bottom:1px solid #b1b1b1;}

.level2-content-top .level2-driver-details {float: left; }
.level2-content-top .level2-driving-details {float: right;text-align:right; }
.level2-content-top .level2-driver-details li:first-child, .level2-content-top .level2-driving-details li:first-child{font-size:14px;color:#1d1d1b;}
.level2-content-top .level2-driver-details li:nth-child(2), .level2-content-top .level2-driving-details li:nth-child(2){font-size:14px;color:#808081;}

/*.sidebar-map-content .level2-content table{width:100%;}
.sidebar-map-content .level2-content table tr:first-child td{font-size:16px;color:#1d1d1b;}
.sidebar-map-content .level2-content table tr:nth-child(2) td{font-size:15px;color:#808081;}
.sidebar-map-content .level2-content table tr td:last-child{text-align:right;}*/
/* .sidebar-map-content .level2-content-inner{margin-top:20px;} */
.sidebar-map-content .level2-content-inner h5{margin-top:5px;margin-bottom:5px;color:#808081;}
.sidebar-map-content .level2-content-inner p.level2-address{color:#1d1d1b;font-size:13.5px;}
.sidebar-map-content .level2-content-inner p.level2-gps{color:#1d1d1b;font-size:10px;}
.location-detail-box .level2-content-inner p.level2-gps{color:#1d1d1b;font-size:10px;}
.sidebar-map-content .level2-content-middle ul{margin:0px;padding:0px; text-align: center; display: flex; justify-content: space-between;}
.sidebar-map-content .level2-content-middle ul li{display:inline;}
.sidebar-map-content .level2-content-middle ul li span{color:#575756;}
.sidebar-map-content .level2-content-middle ul li span:first-child {position: relative; top: 2px; }
.sidebar-map-content .level2-content-middle ul li:first-child{margin:0;margin-left: 10px;}
/* .sidebar-map-content .level2-content-middle ul li span:first-child{margin-right:8px;} */

.map-content-level3 table td:first-child{width:60%;}
.sidebar-map-content .level3-heading .level3-heading-left span{font-weight: 700; font-size: 14px; color: #808081;}
.sidebar-map-content .level3-heading .level3-heading-left .dots-wrap{display: inline-block; margin-left: 25px; line-height: 20px; }
.sidebar-map-content .level3-heading .level3-heading-left .dots-wrap .dot {height: 11px; width: 11px; background-color: #808081; border-radius: 50%; display: inline-block; margin:0 4px; }
.map-content-level3 a.collapsed .level3-heading-right span{transform:rotate(315deg)}
.map-content-level3 a .level3-heading-right span{transform:rotate(0deg);font-size:12px;}
.map-content-wrap .map-locator > ul > li:first-child{display:block;}


/*Map locations*/
.map-locator button {border: none; background: transparent;}
.map-locator .circle { border-radius: 50%; }
.map-locator .outer {width: 15px; height: 15px; position: relative; border-radius: 50%;margin:0 auto;}
.map-locator .inner {top: 16%; left: 14%; width: 7px; height: 7px; position: relative; }
.map-locator button > ul li:last-child{padding: 2px 7px; margin: 4px 2px; border-radius: 3px; color: #ffffff; font-size: 11px;font-weight:700;}

/* .map-location-1{position:absolute;left:10%;top:20%;} */
.map-location-1.map-locator .inner {background-color: #662482;}
.map-location-1.map-locator .outer {border: 2px solid #662482; }
.map-location-1.map-locator button > ul li:last-child{color:#ffffff;background: #662482;-webkit-box-shadow: 2px 2px 0px 0px rgba(50, 50, 50, 0.75);
-moz-box-shadow:2px 2px 0px 0px rgba(50, 50, 50, 0.75);
box-shadow:2px 2px 0px 0px rgba(50, 50, 50, 0.75);}

/* .map-location-2{position:absolute;left:20%;top:15%;} */
.map-location-2.map-locator .inner {background-color: #e42228;}
.map-location-2.map-locator .outer {border: 2px solid #e42228; }
.map-location-2.map-locator button > ul li:last-child{color:#ffffff;background: #e42228;-webkit-box-shadow: 2px 2px 0px 0px rgba(50, 50, 50, 0.75);
-moz-box-shadow:2px 2px 0px 0px rgba(50, 50, 50, 0.75);
box-shadow:2px 2px 0px 0px rgba(50, 50, 50, 0.75);}

/* .map-location-3{position:absolute;left:15%;top:45%;} */
.map-location-3.map-locator .inner {background-color: #f9cf00;}
.map-location-3.map-locator .outer {border: 2px solid #f9cf00; }
.map-location-3.map-locator button > ul li:last-child{background: #f9cf00;-webkit-box-shadow: 2px 2px 0px 0px rgba(50, 50, 50, 0.75);
-moz-box-shadow:2px 2px 0px 0px rgba(50, 50, 50, 0.75);
box-shadow:2px 2px 0px 0px rgba(50, 50, 50, 0.75);}

/* .map-location-4{position:absolute;left:5%;top:65%;} */
.map-location-4.map-locator .inner {background-color: #68b32e;}
.map-location-4.map-locator .outer {border: 2px solid #68b32e; }
.map-location-4.map-locator button > ul li:last-child{background: #68b32e;-webkit-box-shadow: 2px 2px 0px 0px rgba(50, 50, 50, 0.75);
-moz-box-shadow:2px 2px 0px 0px rgba(50, 50, 50, 0.75);
box-shadow:2px 2px 0px 0px rgba(50, 50, 50, 0.75);}

/* .map-location-5{position:absolute;left:12%;top:80%;} */
.map-location-5.map-locator .inner {background-color: #f9cf00;}
.map-location-5.map-locator .outer {border: 2px solid #f9cf00; }
.map-location-5.map-locator button > ul li:last-child{background: #f9cf00;-webkit-box-shadow: 2px 2px 0px 0px rgba(50, 50, 50, 0.75);
-moz-box-shadow:2px 2px 0px 0px rgba(50, 50, 50, 0.75);
box-shadow:2px 2px 0px 0px rgba(50, 50, 50, 0.75);}

/* .map-location-6{position:absolute;left:28%;top:22%;} */
.map-location-6.map-locator .inner {background-color: #1d70b7;}
.map-location-6.map-locator .outer {border: 2px solid #1d70b7; }
.map-location-6.map-locator button > ul li:last-child{background: #1d70b7;-webkit-box-shadow: 2px 2px 0px 0px rgba(50, 50, 50, 0.75);
-moz-box-shadow:2px 2px 0px 0px rgba(50, 50, 50, 0.75);
box-shadow:2px 2px 0px 0px rgba(50, 50, 50, 0.75);}

/* .map-location-7{position:absolute;left:34%;top:10%;} */
.map-location-7.map-locator .inner {background-color: #68b32e;}
.map-location-7.map-locator .outer {border: 2px solid #68b32e; }
.map-location-7.map-locator button > ul li:last-child{background: #68b32e;-webkit-box-shadow: 2px 2px 0px 0px rgba(50, 50, 50, 0.75);
-moz-box-shadow:2px 2px 0px 0px rgba(50, 50, 50, 0.75);
box-shadow:2px 2px 0px 0px rgba(50, 50, 50, 0.75);}

/* .map-location-8{position:absolute;left:32%;top:45%;} */
.map-location-8.map-locator .inner {background-color: #1d1d1b;}
.map-location-8.map-locator .outer {border: 2px solid #1d1d1b; }
.map-location-8.map-locator button > ul li:last-child{background: #1d1d1b;-webkit-box-shadow: 2px 2px 0px 0px rgba(50, 50, 50, 0.75);
-moz-box-shadow:2px 2px 0px 0px rgba(50, 50, 50, 0.75);
box-shadow:2px 2px 0px 0px rgba(50, 50, 50, 0.75);}
.map-locator a > ul li {text-align:center;}
/* .location-detail-box{display:none;} */
/* .location-detail-box.panel{position:absolute;width:300px;-webkit-box-shadow: 4px 4px 0px 0px rgba(217, 217, 217, 0.6);
-moz-box-shadow:4px 4px 0px 0px rgba(217, 217, 217, 0.6);
box-shadow:4px 4px 0px 0px rgba(217, 217, 217, 0.6);border-radius:0;z-index:1;} */

/* #3ba935
#eee411
#1d70b7
#e20613 red */
.location-detail-box .panel-heading.map-content-level2{background: #d9d9d9; position: relative;border-radius: 0;padding: 5px 10px; line-height: 1.4;}
.location-detail-box .level2-heading .level2-heading-left{display:inline; display: flex;}
.location-detail-box .map-content-level2 .level2-heading span {font-size: 17px; font-weight: 700; display: flex; align-items: center;}
.location-detail-box .level2-heading .level2-heading-left span.map-green-bg {background: #3ba935; }
.location-detail-box .level2-heading .level2-heading-left span.map-red-bg {background: #e20613; }
.location-detail-box .level2-heading .level2-heading-left span.map-blue-bg {background: #1d70b7; } /* background: #1d70b7;*/
.location-detail-box .level2-heading .level2-heading-left span.map-violet-bg{background: #662482;}
.location-detail-box .level2-heading .level2-heading-left span.map-black-bg{background: #000;}
.location-detail-box .level2-heading .level2-heading-left span.bg-left {width: 6px; height: 34px; position: absolute; left: 0; top: 0; }
.location-detail-box .level2-heading .level2-heading-left span {margin-right: 5px; color: #1d1d1b; }
.location-detail-box .level2-heading .level2-heading-right{float: right; line-height: 25px; height: 25px; }
.location-detail-box .level2-heading .level2-heading-right ul, .map-location-3 .level2-heading .level2-heading-right span {float: left; }
.location-detail-box .level2-heading .level2-heading-right ul li {display: inline; margin: 0 2px; }
.location-detail-box .list-group-item {border-radius: 0; display: inline-block; padding: 0; background: none; border: none; }
.location-detail-box .list-group-item {border-radius: 0; display: inline-block; padding: 0; background: none; border: none; }
.location-detail-box .level2-heading .level2-heading-left span.map-yellow-bg {background: #eee411; }
.location-detail-box .level2-heading .level2-heading-left span.bg-left {width: 6px; height: 34px; position: absolute; left: 0; top: 0; }
.location-detail-box .level2-heading .level2-heading-left span {margin-right: 5px; color: #1d1d1b; }
.location-detail-box .map-content-level2 .level2-heading span {font-size: 17px; font-weight: 700; }
.location-detail-box .level2-heading .level2-heading-right ul li a span {color: #9c9b9b; margin: 0 3px; }
.location-detail-box .level2-heading .level2-heading-right span {float: left; }
.location-detail-box .level2-heading .level2-heading-right ul+a span {font-size:14px;color:#9c9b9b;margin:0 3px;}
.location-detail-box .level2-heading .level2-heading-right ul+a:hover,.location-detail-box .level2-heading .level2-heading-right ul+a:active,.location-detail-box .level2-heading .level2-heading-right ul+a:focus{background:transparent;}
.location-detail-box .level2-content {padding: 5px 0 0 !important; }
.location-detail-box .level2-content-top {padding: 0 32px; }
.location-detail-box .level2-content table {width: 100%; }
.location-detail-box .level2-content table tr:first-child td {font-size: 16px; color: #1d1d1b; }
.location-detail-box .level2-content table tr:first-child td {font-size: 16px; color: #1d1d1b; }
.location-detail-box .level2-content table tr:nth-child(2) td {font-size: 15px; color: #808081; }
.location-detail-box .level2-content-middle {border-top: 1px solid #b1b1b1; border-bottom: 1px solid #b1b1b1; }
.location-detail-box .level2-content-middle ul {margin: 0px; /*padding: 10px;*/ align-items: center;padding: 0px;display: flex;justify-content: space-between;}
.location-detail-box .level2-content-middle ul li:first-child {margin: 0; margin-left: 10px;}
.location-detail-box .level2-content-middle ul li {display: inline; margin: 0 5px; }
.location-detail-box .level2-content-middle ul li:last-child{margin-left:15px;}
.location-detail-box .level2-content-middle ul li:last-child span{margin-right:0px;font-size:16px;}
.location-detail-box .level2-content-middle ul li span:first-child {margin-right: 5px; }
.location-detail-box .level2-content-middle ul li span:first-child {position: relative; top: 2px; }
.location-detail-box .level2-content-middle ul li span {color: #575756; }
.location-detail-box .level2-content-bottom {margin-top: 7px; }
.location-detail-box .level2-content-bottom.panel-body{padding:0px;}
.location-detail-box .level2-content-bottom .panel.map-content-level3 {border: none; border-bottom: 1px solid #1d1d1b; }
.location-detail-box .panel{border-radius: 0; }
.location-detail-box .level2-content-bottom > .panel.map-content-level3 > .panel-heading {padding: 2px 10px; }
.location-detail-box .panel-heading {border-radius: 0; }
.location-detail-box .panel-heading {padding: 5px 10px; }
.location-detail-box .level3-heading .level3-heading-left {display: inline; }
.location-detail-box .level3-heading .level3-heading-left span {font-weight: 700; font-size: 14px; }
.location-detail-box .level3-heading .level3-heading-left .dots-wrap {display: inline-block; margin-left: 25px; line-height: 20px; }
.location-detail-box .level3-heading .level3-heading-left .dots-wrap .dot {height: 6px; width: 28px; background-color: #808081; border-radius: 5px; display: inline-block; margin: 0 4px;}
.location-detail-box .level3-heading .level3-heading-left span {font-weight: 700; font-size: 14px; }
.location-detail-box .level3-heading .level3-heading-right {float: right; line-height: 25px; height: 25px; }
.location-detail-box .level2-content-bottom .panel.map-content-level3 .panel-body{padding:10px;}
.location-detail-box a .level3-heading-right span {transform: rotate(0deg);font-size:12px; }
.location-detail-box .list-group-item:hover{background:transparent;}
.location-detail-box .level2-content-bottom .panel-group{margin-bottom:10px;}

.content-green-borderbg {
    border-left: 6px solid #3ba935;
}

.content-green-bg {
    background: #3ba935;
}

.content-yellow-borderbg {
    border-left: 6px solid #eee411;
}

.content-yellow-bg {
    background: #eee411;
}

.content-gray-borderbg {
    border-left: 6px solid #8f8d8d;
}

.content-gray-bg {
    background: #8f8d8d;
}

/* The container */
.containerCheck {
    display: inline-block;
    position: relative;
    
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

  }
  
  /* Hide the browser's default checkbox */
  .containerCheck input {
    position: relative;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    z-index: 10000;
    left: 0px;
    /* right: 0; */
    top: 0px;
    bottom: 0;
    margin-top: 12px;
    height: 17px;
    width: 17px;
    background-color: #eee;
  }
  
  /* On mouse-over, add a grey background color */
  .containerCheck:hover input ~ .checkmark {
    background-color: #fff;
  }
  
  /* When the checkbox is checked, add a blue background */
  .containerCheck input:checked ~ .checkmark {
    background-color: #bf2349;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .containerCheck input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .containerCheck .checkmark:after {
    left: 5px;
    top: 0px;
    width:6px;
    height: 12px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

